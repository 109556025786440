import { message } from 'antd'
import axios from 'axios'

import store from '../redux/store'
import { prodAPIURL } from './globalConfig'

const storeInstance = store()
const CancelToken = axios.CancelToken
export const cancelSource = CancelToken.source()

const requestInterceptor = [
  (config) => {
    config.cancelToken = cancelSource.token
    return config
  },
  (error) => console.error(error),
]

const responseInterceptor = [
  (response) => {
    return response
  },
  (error) => {
    console.error(error)
    message.error(error?.message || 'Something went wrong!!')
    return Promise.reject(error)
  },
]

export let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const prodApiInstance = axios.create({
  baseURL: prodAPIURL,
})

axiosInstance.interceptors.request.use(requestInterceptor)
axiosInstance.interceptors.response.use(responseInterceptor)

prodApiInstance.interceptors.request.use(requestInterceptor)
prodApiInstance.interceptors.response.use(responseInterceptor)

const listner = () => {
  const { authToken } = storeInstance.getState().auth
  if (authToken) {
    axiosInstance.defaults.headers.common.Authorization = `${authToken}`
  }
}

storeInstance.subscribe(listner)

export default axiosInstance
