import { combineReducers } from 'redux'

import * as types from '../types'
import auth from './auth.reducer'
import contests from './contests.reducer'
import theme from './theme.reducer'

const appReducer = combineReducers({
  auth,
  contests,
  theme,
})

const rootReducer = (state, action) => {
  switch (action.type) {
    case types.LOG_OUT:
      state = undefined
    // eslint-disable-next-line no-fallthrough
    default:
      return appReducer(state, action)
  }
}

export default rootReducer
