import React, { Component } from 'react'

import { Checkbox } from 'antd'

class CheckBoxGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedList: this.props.defaultData._ids,
      checkAll: false,
      onloadOptions: [],
    }
  }

  assignToFilters = () => {
    const filters = this.props.selectedFilters.map((item) =>
      item.genre === this.props.defaultData.genre
        ? { ...item, _ids: this.state.checkedList }
        : item
    )
    this.props.setSelectedFilters(filters)
  }

  onCheck = (e) => {
    const values = this.state.onloadOptions.map((record) => record.value)
    this.setState(
      {
        checkAll: e.target.checked,
        checkedList: e.target.checked ? values : [],
      },
      () => this.assignToFilters()
    )
  }

  onGroupChange = (checkedList) => {
    this.setState(
      {
        checkedList,
        checkAll: checkedList.length === this.state.onloadOptions.length,
      },
      () => this.assignToFilters()
    )
  }

  componentDidMount() {
    const data = this.props.filterItem.contests.map((contestOptions) => {
      return {
        label: contestOptions.name,
        value: contestOptions._id,
      }
    })
    this.setState({ onloadOptions: data }, () => {
      if (
        this.state.onloadOptions.length === this.props.defaultData._ids.length
      )
        this.setState({ checkAll: true })
    })
  }

  render() {
    return (
      <div className="filterblockwrapper" key={this.props.filterItem.genre}>
        <div className="filterblocktop">
          <h5>
            {this.props.filterItem.genre} -{' '}
            {this.props.filterItem.contests.length}
          </h5>
          <div className="site-checkbox-all-wrapper">
            <label className="ant-checkbox-wrapper ant-checkbox-wrapper-checked">
              <span className="ant-checkbox ant-checkbox-checked">
                <Checkbox onChange={this.onCheck} checked={this.state.checkAll}>
                  check all
                </Checkbox>
                <span className="ant-checkbox-inner"></span>
              </span>
              {this.state.checkAll ? (
                <span>Deselect All</span>
              ) : (
                <span>Select All</span>
              )}
            </label>
          </div>
        </div>
        <div className="filterblockbottom">
          <div className="form-block">
            <Checkbox.Group
              options={this.state.onloadOptions}
              onChange={this.onGroupChange}
              value={this.state.checkedList}
              style={{ width: '100%', marginLeft: '5%', fontWeight: 'lighter' }}
            ></Checkbox.Group>
          </div>
        </div>
      </div>
    )
  }
}

export default CheckBoxGroup
