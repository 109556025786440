let fa = {
  translations: {
    /***************************************************
     * Landing Page: Menu
     ***************************************************/
    home: 'صفحه اصلی',
    aboutUs: 'درباره ما',
    ctm: 'مدیریت استعداد کانتستی (CTM)',
    becomeHost: 'میزبان شوید',
    grandPrize: 'جایزه',
    certificates: 'گواهینامه ها',
    becomeAmbassador: 'نماینده شوید',
    features: 'امکانات',
    whyContestee: 'چرا کانتستی',
    app: 'اپليكيشن',
    howItWorks: 'چگونه کار می کند',
    faqs: 'سوالات',
    contactUs: 'تماس با ما',
    login: 'ورود',
    prizes: 'جايزه ها',
    mall: 'فروشگاه',
    advertiseWithUs: 'اسپانسر شوید',
    userGuides: 'راهنمای کاربران',
    voterGuide: 'راهنمای رأی دهندگان',
    contestantGuide: 'راهنمای شرکت کنندگان',
    /**
     * Landing Page: Slider
     */
    downloadTheContesteeApp: 'ثبت نام در اپلیکیشن Contestee',
    uploadVideosShowcasingYourTalent:
      'به نمایش گذاشتن ویدیوهایی از استعداد شما',
    doYouWantToBeFamous: 'سکوی کشف استعداد جهانی',
    earnVotes: 'کسب رأی',
    winAContestAndBecomeFamous: 'برنده شدن مسابقه و مشهور شدن',
    /**
     * Landing Page: Main Contents
     */
    step: 'مرحله',
    ourHosts: 'میزبانان ما',
    ourContests: 'مسابقات ما',
    ourContestsDetail:
      'ویژگی های بی پایان Contestee در هزاران رشته  و دسته بندی های مختلف.',
    whoIsTheBest: 'Who Is The Best',
    whoIsTheMost: 'Who Is The Most',
    statisticsSoFar: 'آمار Contestee',
    statisticsSoFarContent:
      'Contestee در مسیر تبدیل شدن به بزرگترین سکوی کشف استعداد جهانی می باشد.',
    contests: 'مسابقات',
    contestants: 'شرکت کنندگان',
    users: 'کاربران',
    voters: 'رأی دهندگان',
    hosts: 'میزبانان',
    rapper: 'Rapper',
    athlete: 'Athlete',
    fit: 'Fit',
    model: 'Model',
    cook: 'Cook',
    singer: 'Singer',
    gamer: 'Gamer',
    dancer: 'Dancer',
    talentedKid: 'Talented Kid',
    makeupArtist: 'Makeup Artist',
    graffitiArtist: 'Graffiti Artist',
    actor: 'Actor',
    talentedPet: 'Talented Pet',
    fashionDesigner: 'Fashion Designer',
    photographer: 'Photographer',
    painter: 'Painter',
    musician: 'Musician',
    whatIsContestee: 'Contestee  چیست؟',
    whatIsContesteeContent:
      'در زبان انگلیسی، "contestee"  فردی است که در یک مسابقه رقابت می کند. اپلیکیشن  Contestee، یک شبکه اجتماعی استعدادیابی می باشد که هدف آن کشف استعدادهای جهانی از طریق مسابقات می باشد. در contestee، افراد سراسر جهان می توانند استعداد، مهارت و افتخارهای شگفت انگیز خود را با زدن یک دکمه، به مرحله نمایش بگذارند. افراد با استعداد می توانند ویدیوهای خود را آپلود نموده، رأی کسب کرده و معروف شوند.',
    watchVideo: 'مشاهده ویدیو',
    /**
     * Landing Page: Main Contents - Components Of The Contestee App
     */
    componentsOfTheContesteeApp: 'کاربران Contestee',
    contestantsComponentDescription:
      'Contestee  سه نوع مختلف کاربر را دارا می باشد که دارای سه نقش مختلف هستند.',
    componentSliderContent:
      'پس از گزینش، شما می توانید در مسابقات میزبانان شرکت نموده و شانس خود را برای برنده شدن به عنوان Megastar بیازمایید. شما همچنین می توانید بسادگی ویدیوهایی از استعدادهای خود را در مسابقات Popularity  آپلود نموده و بعد از کسب آرا از مردم در سراسر جهان مشهور شوید.',
    votersComponentDescription:
      'ویدیوهای مختلف افراد با استعداد را تماشا کرده و به آنها رأی دهید. با دادن رأی بیشتر، قدرت رأی دهی شما از 1 تا 50 افزایش پیدا خواهد کرد و در هر مرحله شما شایستگی دریافت جوایز نفیسی را دارید از جمله بلیط رأی دهی مجانی، Apple Watch و Iphone Pro Max  12 .',
    hostsComponentDescription:
      'به عنوان هنرمندان مشهور و محبوب یا کمپانی ها، شما این فرصت را دارید که مسابقات دیجیتالی خود را میزبانی کنید و افراد دارای استعداد  می توانند در مسابقات میزبانان شرکت نموده، شهرت کسب کرده، برنده مسابقه شوند و شغل جدید خود را بهتر بسازند.',
    votingTickets: 'بلیط های رأی دهی',
    votingTicketsComponentDescription:
      'Earn tickets by using the app and cast your votes to help your favorite contestants win. Regular tickets = 1 vote Gold tickets = 3 votes Platinum tickets = 5 votes',
    talentAdvisors: 'Talent Advisors',
    talentAdvisorsComponentDescription:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero',
    talentEvaluators: 'Talent Evaluators',
    talentEvaluatorsComponentDescription:
      'Simply upload videos showcasing your talent, skill, or attribute. Get votes from people around the world to become famous and win contest prizes.',
    learnHowContesteeWorks: 'چگونه Contestee کار می کند',
    typesOfContestsInContestee:
      'در زندگی واقعی، افراد با استعداد با دو فاکتور قضاوت می شوند: اینکه چقدر محبوب هستند و اینکه واقعاً چقدر مهارت و یا استعداد دارند. به همین دلیل دو نوع مسابقه در کانتستی وجود دارد:',
    popularityContestsDescription:
      '<strong>مسابقات محبوبيت، </strong>  شبیه سایر رسانه های اجتماعی می باشند، که هر فرد با استعدادی می تواند به راحتی و با بارگذاری یک فیلم از استعداد خود به منظور کسب رأی ، به طور رایگان به اين مسابقات بپیوندد. با بدست آوردن آرای کافی از طریق ویدیوهای خود می توانید به مراحل جدید محبوبیت دست یافته و جوایز مربوط به آن مرحله را کسب نمایید. ',
    hostContestsDescription:
      '<strong>مسابقات میزبانان، </strong> شبیه مسابقات رايج تلویزیونی هستند ، که فرد در ابتدا لازم است در قسمت گزینش شرکت کرده و توسط میزبان و داوران پذیرفته شود. سپس شرکت کنندگان بایستی تعداد رأی کافی را در هر مرحله از رأی دهندگان کسب نموده و در آخرین مرحله برنده شوند. همچنین میزبان می تواند از دکمه Star Buzzer  برای اعطای 10 میلیون و یا 50 میلیون رأی به فرد برنده شده استفاده کرده و بدین ترتیب او را به جایزه Megastar نزدیک تر نماید.',
    watchContestVideo: 'مشاهده ویدیوی مسابقه',
    watchHostContestVideo: 'مشاهده ویدیوی مسابقه میزبان',
    watchVoterVideo: 'مشاهده ویدیوی رأی دهنده',
    learnHowContesteeWorksDesc1:
      'چنانچه شما جمعاَ به 100 میلیون رأی دست یابید، یک  <strong>Megastar</strong> شده و جایزه 100,000 دلاری را کسب خواهید نمود.',
    learnHowContesteeWorksDesc2:
      'علاوه براین، شما می توانید برای <strong> مدیریت استعداد کانتستی (CTM)  </strong>واجد شرایط باشید  <em>(که تمامی پشتیبانی های لازم را برای ارتقای شغل خود را خواهید داشت)</em>، همچنین در  <strong> لیست استعداد جهانی </strong> قرار خواهید گرفت <em>(برای مشهور شدن و دریافت فرصت های جدید)</em>. در مسابقات میزبانان، میزبان می تواند افراد با استعداد را برای برنامه CTM کاندید کند.',
    learnHowContesteeWorksDesc3: 'این جدول را برای مقایسه بهتر مشاهده نمایید:',
    becomeAHost: ' درخواست برای ميزبان شدن',
    becomeAHostDescription:
      'مسابقات خود را میزبانی کنید و شهرت خود را همراه با طرفداران با استعداد به اشتراک بگذارید! اگر شما یک فرد مطرح و مشهور یا کمپانی هستید، می توانید مسابقه شخصی خود را در زمینه های مختلف برگزار کنید. اپلیکیشن Contestee  برای کمک به شما در اجرای آسان مسابقات و افزایش ارتباط با طرفداران با استعداد طراحی شده است.',
    the_100KGrandPrize: ' جایزه بزرگ ۱۰۰ هزار دلاری ',
    the_100KGrandPrizeDesc1:
      ' علاوه بر جوایز مسابقه ، Contestee دارای یک <strong>جایزه بزرگ ۱۰۰ هزار دلاری </strong>  برای تبدیل شدن به یک استعداد Megastar می باشد. به عنوان یک Megastar ، یک فرد با استعداد برای برنامه مدیریت استعدادهای Contestee که همان CTM Program است، واجد شرایط می شود و با پشتیبانی افراد حرفه ای در Contestee، می تواند آینده استعداد و هنر خود را بهتر دنبال کند. برای تبدیل شدن به یک استعداد Megastar، لازم است که 100 میلیون رأی کسب شود.',
    the_100KGrandPrizeDesc2:
      'قدرت رأی دادن و بلیط های رأی دهی به این معنی است که رسيدن به جايگاه Megastar را آسان تر می کنند. گرفتن 100 میلیون رأی به نظر مشکل می آید که شاید برای این مقدار رأی، نیاز به زمان بیشتری باشد. به همین دلیل راه حل دومی وجود دارد.  ',
    the_100KGrandPrizeDesc3:
      'در <strong>مسابقه میزبانان</strong>، میزبان با استفاده از دکمه  <strong>Star Buzzer</strong> می تواند 10 میلیون و یا 50 میلیون رأی به برنده مسابقه بدهد و آنها را به یک استعداد استار و یا سوپر استار تبدیل كند! بدين وسيله، برنده میتواند در مسیر کسب بقیه آرا برای Megastar شدن و پیروزی در مسابقه، شتاب لازم را بدست اورد.',
    theContesteeTalentManagementProgram: 'برنامه مديريت استعدادهای Contestee',
    theContesteeTalentManagementProgramDesc1:
      ' <strong>برنامه مديريت استعداد Contestee </strong> ، برای فراهم كردن زمينه رشد و ارتقا استعدادهای واجد شرایط به وجود امده است. هدف اصلی برنامه CTM کمک به استعدادها برای تبدیل شدن به ستاره های فردا است.',
    theContesteeTalentManagementProgramDesc2:
      'برای تعیین صلاحیت، لازم است که شرکت کنندگان دارای استعداد توسط یک میزبان مسابقه کاندید شوند. این مهم نیست که این فرد در مسابقه برنده شود. میزبان می تواند هر فردی که به نظر می رسد می تواند یک ستاره شود را کاندید کند. سپس افراد کاندید شده توسط هیئت بازنگری حرفه ای مورد بررسی قرار می گیرند و آنها تصمیم می گیرند که آیا فرد کاندید شده برای مسابقه واجد شرایط می باشد یا نه. سپس افراد واجد شرایط، یک قرارداد با کمپانی Contestee امضا کرده تا اینکه بتوانند از طرف Contestee برای پیشرفت اهداف خود حمایت شوند. ',
    certificatesDescription:
      'Contestee برای کمک به افراد با استعداد به منظور توسعه و رشد دادن استعدادهای آنها متمرکز شده است. هر زمانیکه یک شرکت کننده به سطح محبوبیت بالا برسد، یک گواهینامه رسمی از کمپانی Contestee دریافت کرده و آنچه را که کسب کرده اند در این گواهینامه ذکر خواهد شد که می توانند آن را در مدرک حرفه ای از استعداد خود، برای اشتغال، در شبکه های اجتماعی و یا هر جای دیگر به اشتراک بگذارند. ',
    /**
     * Landing Page: Main Contents - Why Choose Contestee?
     */
    whyChooseContestee: 'چرا Contestee؟',
    whyChooseContesteeDescription1:
      'بسیاری از افراد با استعداد در جهان هستند که برای مطرح شدن تلاش می کنند. تلاش برای شناخته شدن و در عین حال کسب طرفداران از طریق شبکه های اجتماعی، سال های زیادی به طول می انجامد. افراد با استعداد جدید سعی می کنند که در میان ستارگان و افراد مشهور شناخته شوند. طبیعتاَ در این مسیر بسیاری از افراد مأیوس می شوند، Contestee آماده کمک به این افراد می باشد. ',
    whyChooseContesteeDescription2:
      'در یک استیج جهانی که باعث شهرت افراد می شود، هر شخص با استعدادی می تواند فرصت های جدیدی را داشته باشد. ',
    ourMission: 'هدف اصلی ما',
    ourMissionDescription: 'کشف استعدادهای جهانی از طریق مسابقات آنلاین ',
    ourVision: 'تصور ما',
    ourVisionDescription: 'تبدیل شدن به اولین لیست استعدادهای جهانی ',
    ourGoal: ' هدف ما',
    ourGoalDesc:
      'هدف Contestee فراهم نمودن یک مکان برای ارزش دادن به فعالیت تمام کاربران و همچنین سکویی سرگرم کننده و جذاب.',
    ourGoalDesc1:
      '<strong>رأی دهندگان:</strong> فراهم کردن محلی برای افراد که بتوانند ویدیوهای مختلف در رشته های گوناگون را مشاهده کرده و با دادن رأی به این استعدادها از آنها حمایت نموده و تأثیرگذار باشند. ',
    ourGoalDesc2:
      '<strong>شرکت کنندگان:</strong> ساختن یک صحنه نمایش جهانی برای افراد با استعداد در سراسر جهان که بتوانند سریع و راحت مطرح و معروف شوند. ',
    ourGoalDesc3:
      '<strong>میزبانان:</strong> فراهم نمودن سکویی برای افراد مشهور و مطرح به منظور ایجاد موقعیت های جدید و برقراری ارتباط با طرفداران با استعداد و رأی دهندگان توسط مسابقات میزبانان و انتقال تجربه به ستاره های آینده. ',
    becomeAnAmbassador: 'تبدیل شدن به یک نماینده ',
    becomeAnAmbassadorDesc1:
      'Contestee بطور فعال "نمایندگان" را به عنوان سفیر خود قبول می کند، اشخاصی با تجربه و شناخته شده در جامعه که دارای ارتباطات نزدیک با افراد با تجربه و شناخته شده دیگر جامعه هستند که به عنوان نماینده می توانند از آنها دعوت به همکاری کنند. در صورت تمایل برای نماینده شدن می توانید',
    becomeAnAmbassadorDesc2:
      'تعدادی از نمایندگان Contestee را در زیر می توانید مشاهده کنید.',
    becomeAnAmbassadorDesc3: 'با ما در تماس باشید.',
    appScreens: 'صفحه های نمایش اپلیکیشن',
    faqS: 'سؤال و جواب های متداول',
    whatAreTheTwoTypesOfContests: 'What are the two types of contests?',
    whatAreTheTwoTypesOfContestsDesc1:
      'Although both types of contests take you to the same place, they offer two different paths:',
    whatAreTheTwoTypesOfContestsDesc2:
      '<strong>Popularity Contests</strong> are inclusive and casual,are like social media in that they’re open for everyone to share their talents. Earn votes to increase your popularity level and show off your social status.',
    whatAreTheTwoTypesOfContestsDesc3:
      '<strong>Host Contests</strong> are for people who want a fast-track to becoming famous. They are more like traditional contests, with multiple rounds for voters from around the world to watch and vote for the best. Each contest is hosted by a talented professional, such as a celebrity or influencer.',
    whatIsTheMegastarPrize: 'جایزه Megastar چیست؟',
    whatIsTheMegastarPrizeDesc1:
      'جایزه مگا استار 100 هزار دلار می باشد که هر کس می تواند توسط کسب 100 میلیون رأی در Contestee برنده شود. رسیدن به این مرحله به آن سختی که به نظر میرسد نمی باشد، چرا که',
    whatIsTheMegastarPrizeDesc2:
      'رأی دهندگان می توانند چندین بار در روز رأی دهند. ',
    whatIsTheMegastarPrizeDesc3:
      'رأی دهندگان می توانند قدرت رأی دهندگی بالاتر داشته باشند. ',
    whatIsTheMegastarPrizeDesc4:
      'رأی هایی که در هر مسابقه کسب می کنید کمک به حصول موفقیت می کنند. ',
    whatIsTheMegastarPrizeDesc5:
      'به محض برنده شدن در یک مسابقه میزبان،  <u> 10 میلیون </u> و یا <u> 50 میلیون</u> بلیط رأی دهی کسب خواهید کرد. ',
    whatIsContesteeTalentManagementCtm: 'مدیریت استعداد کانتستی یعنی CTM چیست؟',
    whatIsContesteeTalentManagementCtmDesc1:
      'مدیریت استعداد کانتستی یعنی CTM، یک برنامه برای کمک به شرکت کنندگان با استعداد فوق العاده برای پیشرفت موقعیت شغلی آنها می باشد. دو حالت وجود دارد یا اینکه می توانید برای واجد شرایط بودن توسط یک میزبان کاندید شوید و یا به منظور تعیین صلاحیت شدن سریع، یک Megastar شوید. ',
    whatIsContesteeTalentManagementCtmDesc2:
      'به محض اینکه یک فرد با استعداد واجد شرایط شناخته شد، می تواند یک قرارداد با کمپانی Contestee امضا کند و برای پرورش دادن و بهبود بخشیدن استعداد خود و دستیابی به موقعیت های حرفه ای در سرتاسر جهان از این طریق بهره بگیرد. ',
    whatKindsOfTalentsBelongOnContestee:
      'چه استعدادهایی متعلق به اپلیکیشن Contestee هستند؟',
    whatKindsOfTalentsBelongOnContesteeDesc:
      'Contestee برای تمامی افراد با استعداد، با مهارت و هنرمند مناسب می باشد. هزاران دسته بندی مختلف و حداقل یک مسابقه برای هر شخص وجود دارد. این دسته ها شامل آواز خوانی، رقص، موزیک، ورزش، هنر و ... هستند. همواره همراه با افزایش درخواست، انواع جدیدی از مسابقات اضافه خواهند شد.',
    willThereBeNewContestsAddedInTheFuture:
      'آیا مسابقات جدیدی در آینده اضافه خواهد شد؟',
    willThereBeNewContestsAddedInTheFutureDesc1:
      'مسابقات جدید Popularity  بصورت دائم و خودکار ایجاد شده و مبنی بر کاربران اضافه می شوند.',
    willThereBeNewContestsAddedInTheFutureDesc2:
      'مسابقات جدید میزبانان همیشه از میزبانان مسابقات جدید و قدیم اضافه می شوند.',
    whatMakesContesteeDifferentFromRunningAContestOnSocialMedia:
      'چه عاملی Contestee را در اجرا کردن یک مسابقه با شبکه های اجتماعی دیگر متمایز می کند؟',
    whatMakesContesteeDifferentFromRunningAContestOnSocialMediaDesc:
      'Contestee امکانات و عوامل لازم برای برگزاری مسابقه را در اختیار میزبان می گذارد. بعضی از این امکانات عبارتند از صحنه جهانی، سیستم بلیط رأی دهی، زیر ساخت تبلیغات، داوران، جوایز واقعی و امکانات دیگر.',
    howIsTheWinnerDecidedForEachContest: 'چگونه شما مسابقه را برنده می شوید؟',
    howIsTheWinnerDecidedForEachContestDesc1:
      "در  <strong>مسابقات میزبانان</strong>، شرکت کنندگانی که در هر مرحله تعداد رأی کمتری کسب کرده اند، حذف خواهند شد. در آخرین مرحله شرکت کننده ای که بالاترین رأی را کسب کند، برنده مسابقه خواهد بود. ولی این تنها راه برای  <a href='/fa/prizes'> برنده شدن مسابقه</a> نیست...",
    howIsTheWinnerDecidedForEachContestDesc2:
      'از آنجایی که در <strong>در مسابقات محبوبیت </strong> رقابت وجود ندارد، برنده نهایی نیز در آن معنایی ندارد. ولی به هر حال با کسب رأی های بیشتر می توانید به سطح محبوبیت بالاتر رسیده و تبدیل به  یک Megastar شده و برنده جایزه 100,000 دلاری شوید.',
    whatArePopularityLevels: 'چه درجات مختلفی برای محبوبیت وجود دارد؟',
    whatArePopularityLevelsDesc:
      'درجه محبوبیت روشی است برای اندازه گیری اینکه چه تعداد رأی در Contestee کسب شده است. هر زمانیکه تعداد رأی کافی برای مرحله بعد کسب شود، یک گواهینامه رسمی Contestee به شما اعطا می گردد که نمایانگر وضعیت جدید شما می باشد. درجات بالاتر را برای برنده شدن جوایز کسب نمایید و همچنین به سطح Megastar دست یابید به منظور برنده شدن ',
    whatIsContesteeFaq: 'Contestee  چیست؟',
    whatIsContesteeFaqDesc:
      'Contestee اولین سکوی کشف استعدادهای جهانی است همانند TikTok  و America’s Got Talent، هر فرد می تواند اپلیکیشن Contestee را روی گوشی خود دانلود نموده و ویدیوهایی را از استعدادهای خود در مسابقات مختلف آنلاین وارد نماید.',
    whatIsTheGoalOfContestee: 'هدف Contestee  چیست؟',
    whatIsTheGoalOfContesteeDesc:
      'Contestee سه هدف اصلی برای سه نوع از کاربران دارد:',
    whatIsTheGoalOfContesteeGoal1:
      '<strong>رأی دهندگان</strong>– ایجاد محلی که مردم بتوانند انواع مختلفی از ویدیوها را که بر اساس علایق خودشان مرتب شده است را مشاهده کرده و رأی دهند.',
    whatIsTheGoalOfContesteeGoal2:
      '<strong>شرکت کنندگان</strong> – ایجاد یک استیج جهانی برای افراد با استعداد در سرتاسر جهان که بتوانند سریع و راحت مشهور شوند. ',
    whatIsTheGoalOfContesteeGoal3:
      '<strong>میزبانان</strong>– فراهم نمودن سکویی برای هنرمندان مشهور که می توانند فرصت های جدیدی را خلق کرده، با افراد با استعداد در تماس بوده و آنها را راهنمایی کنند.',
    whoCanUseContestee: 'چه کسی می تواند از Contestee استفاده کند؟',
    whoCanUseContesteeDesc:
      'اپلیکیشن Contestee در App Store  و  Google Play رایگان می باشد. هر فردی با یک تلفن یا شماره WhatsApp می تواند یک حساب کاربری ایجاد کرده و سپس رأی داده یا در مسابقه شرکت کند.',
    whatKindsOfPeopleIsContesteeFor: 'Contestee برای چه نوع افرادی است؟',
    whatKindsOfPeopleIsContesteeDesc1:
      'Contestee برای سه نوع افراد ساخته شده است. ',
    whatKindsOfPeopleIsContesteeDesc2:
      '<strong>شرکت کنندگان</strong> - تمامی افراد با استعدادی که می خواهند مشهور و محبوب شوند. ',
    whatKindsOfPeopleIsContesteeDesc3:
      '<strong>میزبانان</strong> - افراد حرفه ای و هنرمندان مشهور که می خواهند میزبان مسابقات خود شوند. ',
    whatKindsOfPeopleIsContesteeDesc4:
      '<strong>رأی دهندگان</strong> - هر فردی که به مسابقات و سرگرمی علاقه مند است.  ',
    howDoYouWinTheMegastarPrize:
      'چگونه می توان جایزه 100 هزار دلاری مگا استار را برنده شد؟',
    howDoYouWinTheMegastarPrizeDesc:
      ' شما می توانید از طریق کسب 100 میلیون رأی به سطح محبوبیت  <em>Megastar</em> رسیده و برنده جایزه Megastar شوید. می توانید رأی ها را توسط شرکت در مسابقات کسب نمایید. ',
    whatTypesOfContestsAreThere: 'چه نوع از مسابقات وجود دارند؟',
    whatTypesOfContestsAreThereDesc:
      'دو نوع مسابقه در Contestee وجود دارند. مسابقات محبوبیت و مسابقات میزبانان که از طریق هر دو می توان جایزه 100 هزار دلاری مگا استار را برنده شد. ',
    whatsTheDifferenceBetweenPopularityContestsHostContests:
      'چه تفاوتی بین مسابقات محبوبیت و مسابقات میزبانان وجود دارد؟',
    whatsTheDifferenceBetweenPopularityContestsHostContestsDesc1:
      '<strong>مسابقات محبوبيت</strong> شبیه سایر شبکه های اجتماعی مي باشند. هیچگونه رقابت و یا نیازمندی وجود ندارد. هر فرد می تواند ویدیوهایی را بارگذاری کرده و از کاربران سراسر جهان رأی کسب نماید. و بدین طریق به سطح جدید مجبوبیت دست یافته و برنده جایزه Megastar شود که مدت آن طولانی است شاید یکسال و یا بیشتر به طول خواهد انجامید.',
    whatsTheDifferenceBetweenPopularityContestsHostContestsDesc2:
      '<strong>مسابقات میزبانان</strong> شبیه مسابقات رايج تلویزیونی هستند. این مسابقات توسط هنرمندان مشهور میزبانی می شوند. در مسابقات میزبانان ابتدا لازم است که یک ویدیو را برای مرحله گزینش ارائه کرده و توسط میزبان پذیرفته شوید. سپس برای آنکه در هر مرحله حذف نشوید، می بایستی تعداد رأی کافی را از ویدیوهای خود در هر مرحله کسب کنید. به منظور برنده شدن در مسابقه و کسب Star Buzzer، لازم است که بالاترین تعداد رأی را در دور آخر کسب نمایید. حتی اگر برنده نشوید، چنانچه میزبان در شما استعدادی را ببیند که صلاحیت دارید، شما را به مدیریت استعدادهای Contestee که همان CTM است معرفی می کند. مدت زمان این مسابقات سریعتر بوده و به مدت چند ماه طول می کشد. ',
    whatIsTheStarBuzzer: 'Star Buzzer چیست؟',
    whatIsTheStarBuzzerDesc:
      'زمانی که فردی در یکی از مسابقات میزبانان برنده می شود، میزبان می تواند این تصمیم را بگیرد که از دکمه Star Buzzer استفاده نماید. با انجام این کار به فرد برنده شده  <strong>10 میلیون</strong> و یا <strong>50 میلیون</strong> رأی داده خواهد شد. سپس فرد برنده شده لازم است که بقیه 50 میلیون رأی را در مسابقات محبوبیت از طرف مردم کسب کند که نشان دهد یک فرد با استعداد و محبوب می باشد.',
    howDoesTheAuditionProcessWork: 'فرایند انجام پذیرش چگونه انجام می شود؟',
    howDoesTheAuditionProcessWorkDesc:
      'قبل از آنکه یک مسابقه میزبان شروع شود، لازم است که یک ویدیو از استعداد خود را برای پذیرش بارگذاری نمایید. میزبان مسابقه این ویدیو را مرور کرده و تصمیم می گیرد که کدامیک را به عنوان شرکت کننده قبول و یا رد کنند. میزبان مسابقه تعیین می کند که چند نفر شرکت کننده در مسابقه حضور داشته باشند. بنابراین هر کسی نمی تواند به عنوان شرکت کننده قبول شود.',
    howDoYouAvoidGettingEliminatedFromAContest:
      'چگونه می توان از حذف شدن در مسابقات میزبانان اجتناب نمود؟',
    howDoYouAvoidGettingEliminatedFromAContestDesc:
      'به هیچ صورت نمی توان ضمانت داد که از مسابقات حذف نشوید. تنها راه حل این است که بیشترین تلاش خود را با بهترین کارایی انجام دهید تا رأی کافی را کسب نمایید.',
    howManyTimesCanIVotePerDay: 'چند بار در روز می توانم رأی دهم؟',
    howManyTimesCanIVotePerDayDesc:
      'شما می توانید به هر تعداد بلیط رأی دهی که دارید ، رأی دهید. محدودیتی برای تعداد رأی دادن  برای ویدیوها در روز وجود ندارد.',

    megastarPrize: 'جایزه مگا استار',
    popularityLevel: 'مرحله محبوبیت',
    numberOfVotesEarned: 'تعداد رأی های کسب شده',
    bronze: 'برنز',
    silver: 'سیلور',
    gold: 'گلد',
    platinum: 'پلاتینیوم',
    star: 'Star',
    superstar: 'Superstar',
    megastar: 'Megastar',
    whatAreContesteeCertificates: 'گواهینامه های Contestee  چیست؟',
    whatAreContesteeCertificatesDesc:
      'به محض اینکه یک فرد با استعداد به یک مرحله جدید محبوبیت رسید، یک گواهینامه رسمی Contestee دریافت خواهد کرد. آنچه را که فرد کسب کرده در این گواهینامه ذکر خواهد شد و افراد می توانند آن را در مدرک حرفه ای از استعداد خود، شبکه اجتماعی و یا هر جای دیگر به اشتراک بگذارند.',
    canISeePeopleSOldContestsAndCertificates:
      'آیا می توان مسابقات و گواهینامه های قدیمی افراد را مشاهده نمود؟',
    canISeePeopleSOldContestsAndCertificatesDesc:
      'بلی، تمامی برنده شدگان در Contestee در یک لیست اصلی، سازمان دهی و دسته بندی شده اند که هر فرد می تواند به آن دسترسی داشته باشد. شما می توانید تاریخچه یک شرکت کننده را از پروفایل او مشاهده نمایید.',
    howDoIBecomeAVoter: 'چگونه می توان یک رأی دهنده شد؟',
    howDoIBecomeAVoterDesc:
      'هر شخص می تواند یک رأی دهنده باشد. به محض ساخت یک حساب کاربری در Contestee، شما می توانید برای افراد با استعداد و مورد دلخواه خود شروع به رأی دهی کنید.',
    howDoIVote: 'چگونه می توان رأی داد؟',
    howDoIVoteDesc:
      'با زدن دکمه Vote که در پایین هر پست قرار دارد می توان برای پست های مورد دلخواه خود رأی دهید. می توانید بر اساس تعداد بلیط های موجود رأی بدهید، ولی هر رأی به اندازه یک تیکت رأی دهی ارزش دارد. این قانون در موارد مسابقات محبوبیت و میزبانان برقرار است.',
    whatAreVotingTickets: 'بلیط های رأی دهی چه هستند؟',
    whatAreVotingTicketsDesc1:
      'به منظور رأی دادن برای یک فرد، لازم است که یک بلیط رأی دهی صرف شود. در ابتدا از 10 بلیط رأی دهی شروع می شود که در هر روز تجدید می شود. این بدان معناست که چنانچه بلیط های رأی دهی صرف نشوند، نمی توان آنها را در روز بعد استفاده نمود.',
    whatAreVotingTicketsDesc2:
      'می توان توسط مشاهده تبلیغات، دعوت دوستان و یا مرور مسابقات، بلیط های رأی دهی بیشتری را کسب نمود.',
    whatIsVotingPower: 'قدرت رأی دهی چیست؟',
    whatIsVotingPowerDesc1:
      'قدرت رأی دهی، تعداد آرایی است که هر بلیط ارزش دارد. برای رسیدن به یک سطح جدید قدرت رأی دهی، لازم است که به تعداد رأی های معینی رسید.',
    whatIsVotingPowerDesc2:
      'رسیدن به یک مرحله جدید، نه تنها تأثیر شما را بر انتخاب برنده مسابقه بیشتر می کند، همچنین می تواند پاداش ویژه از طرف sponsor برای شما کسب کند .',

    voterLevel: 'سطح رأی دهنده',
    voteRequirement: 'نیازمندی برای رأی دادن',
    votingPower: 'قدرت رأی دهی',
    regular: 'معمولی',
    howCanIFilterOrFindCertainContests:
      'چگونه می توان مسابقات مشخصی را فیلتر نموده و یا پیدا کرد؟',
    howCanIFilterOrFindCertainContestsDesc:
      'چنانچه فردی تمایل به دیدن مسابقات و یا ویدیوهای مشخصی ندارد، می تواند دسته بندی های مورد علاقه خود را توسط فیلتر مسابقه انتخاب و تعیین نماید.',
    canISearchForSpecificContestsOrPeople:
      'آیا می توان مسابقات  و یا افراد خاصی را جستجو نمود؟',
    canISearchForSpecificContestsOrPeopleDesc:
      'بلی، از صفحه اول، می توان شرکت کننده، میزبان خاص و یا ویدیوهایی را با برچسب و یا زبان مشخص با استفاده از نوار ابزار جستجو پیدا نمود.',
    whatIsAHost: 'میزبان چیست؟',
    whatIsAHostDesc:
      'یک میزبان، یک فرد مشهور، هنرمند، فرد مطرح و یا یک کمپانی است که مسابقات خود را در Contestee اجرا می کند.',
    whatIsTheDifferenceBetweenPopularityContestsAndHostContests:
      'What is the difference between Popularity Contests and Host Contests?',
    whatIsTheDifferenceBetweenPopularityContestsAndHostContestsDesc1:
      '<strong>Popularity Contests:</strong> Like in social media, anyone can upload photos or videos showing off their talent in order to earn votes from people around the world.',
    whatIsTheDifferenceBetweenPopularityContestsAndHostContestsDesc2:
      '<strong>Host Contests: </strong> Like in traditional contests, people who want to get more exposure, faster can audition to be a part of a contest. The host gets to set the rules and decide who is accepted. Contestants then upload a video each round in order to earn the most votes or else get eliminated. The winner of the final round gets a Star Buzzer from the host as well as any sponsored prize(s).',
    whatDoesAHostDo: 'یک میزبان چه کاری را انجام می دهد؟',
    whatDoesAHostDoDesc1:
      'میزبان، یک و یا چند مسابقه را در اپلیکیشن Contestee به اجرا می گذارد. این در حالیست که آنها مجبور نیستند هر کاری را خودشان اجرا نمایند. اکثر جوانب اجرای یک مسابقه خودکار است و اکثر کارهای مورد نیاز را می توان به ادمین محول نمود. میزبان در مورد ضوابط، قوانین، تاریخ ها و شرکت کننده ها برای یک مسابقه تصمیم گیری می کند. نیز لازم است تایید کند که کدام آگهی دهنده ها در صفحه خود می خواهند و می توانند اسپانسرهایی را برای مسابقه پیدا کنند. میزبان همچنین لازم است که برای مسابقه خود تبلیغ کند و نیز هر چند وقت یک بار با فالوورهای خود در محیط شبکه اجتماعی Contestee در ارتباط باشد. برای آخرین دوره مسابقه، میزبان تصمیم می گیرد که کدام Star Buzzer برای فرد برنده مناسب می باشد و چه فردی مناسب کاندید شدن برای <u>CTM </u> می باشد. ',
    whatDoesAHostDoDesc2:
      'سوالات بیشتری را می توان در قسمت سوالات متداول پاسخ داد و همچنین می توان از یکی از افراد نماینده Contestee که وظیفۀ پشتیبانی و راهنمایی میزبانان و ادمین ها را در مدیریت کردن صفحه آنها برعهده دارد کمک گرفت.',
    whatIsAnAdmin: 'ادمین چیست؟',
    whatIsAnAdminDesc:
      'یک ادمین شخصی است که جنبه های تکنیکی یک مسابقه میزبان را مدیریت می کند. اکثر کارهای یک میزبان در Contestee را می توان به یک ادمین محول نمود. هیچگونه محدودیتی برای تعداد ادمین ها وجود ندارد ولی ادمین بایستی فردی باشد که میزبان بتواند برای دسترسی مستقیم به صفحات او اطمینان داشته باشد. میزبان می تواند ادمین های خود را استخدام نموده و یا مسئولیت را به یک دوست نزدیک و یا یکی از اعضای فامیلی واگذار نماید.',
    whoCanBecomeAHost: 'چه کسی می تواند یک میزبان شود؟',
    whoCanBecomeAHostDesc:
      'فقط افراد محبوب مانند افراد مشهور، هنرمندها، افراد مطرح یا کمپانی ها می توانند میزبان شود. Contestee شخصاً بررسی نموده و با هر متقاضی در ارتباط با تصمیم گیری شایستگی او در ارتباط می باشد.',
    howCanIBecomeAHost: 'چگونه می توان یک میزبان بود؟',
    howCanIBecomeAHostDesc1:
      'چنانچه تصور می کنید که دارای صلاحیت می باشید می توانید از طریق فرمی که در پایین این صفحه قرار دارد و یا از طریق ',
    howCanIBecomeAHostDesc2: 'این لینک',
    howCanIBecomeAHostDesc3:
      '. با ما در تماس باشید. به محض اینکه صلاحیت شما را بررسی کردیم و صحت هویت شما برای ما مشخص شد، جهت کسب اطلاعات بیشتر، ما به شما تماس خواهیم گرفت. ',
    connectWithUs: 'تماس با ما',
    notifyMeOnceContesteeLaunches: 'به محض شروع به کارContestee ، مرا مطلع کن',
    comingSoon: 'بزودی!',
    name: 'نام',
    email: 'ایمیل',
    askUsAnything: 'هر موردی را از ما بپرس',
    submit: 'ورود اطلاعات',
    alex: 'Alex',
    marie: 'Marie',
    adriana: 'Adriana',
    amelia: 'Amelia',
    linda: 'Linda',
    will: 'Will',
    unitedStates: 'United States',
    germany: 'Germany',
    france: 'France',
    australia: 'Australia',
    canada: 'Canada',
    leilaForouhar: 'Leila Forouhar',
    shohrehAghdashloo: 'Shohreh Aghdashloo',
    schubertAvakian: 'Schubert Avakian',
    paksimaZakipour: 'Paksima Zakipour',
    raminZamani: 'Ramin Zamani',
    hamidSaeidi: 'Hamid Saeidi',
    summerAlami: 'Summer Alami',
    monaNikoo: 'Mona Nikoo',
    samiraNosrati: 'Samira Nosrati',
    babakRahimi: 'Babak Rahimi',
    yasamanDance: 'Yasaman Dance',
    pedramCouture: 'Pedram Couture',
    eritMajdipour: 'Erit Majdipour',

    verifyUserInputInformation:
      'Becuase any Talent or Active Voter can win prizes or rewards in contestee, your profile information must be both True & Accurate in order to receive any prizes or rewards.',
    mobileNumber: 'شماره موبایل',
    nowAvailableOn: 'Now available on',
    getTheLinkTo: 'Get the link to',
    installApp: 'install app',
    verifyMobileNumber: 'Verify Mobile Number',
    verificationCode: 'Verification Code',
    weHaveSentVerificationCodeTo: 'We have sent verification code to',
    resend: 'Resend',
    regularUser: 'User Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    select: 'Select',
    country: 'Country',
    emailAddress: 'Email Address',
    byCreatingYourAccountYouAgreeTo: 'By creating your account you agree to',
    termsCondition: 'Terms & Condition',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    done: 'Done',
    copyright: 'Copyright',
    allRightsReserved: 'All Rights Reserved',
    poweredBy: 'Powered By',

    /***************************************************
     * Prizes Page:
     ***************************************************/
    contestWinner: 'برنده مسابقه',
    contestWinnerDescription:
      'فرد با استعدادی که بیشترین رأی را در آخرین مرحله کسب می کند برنده مسابقه خواهد بود. برنده می تواند یک جایزه را از میزبان یا sponsor کسب کند.',
    starBuzzerWinner: 'برنده Star Buzzer',
    starBuzzerWinnerDescription:
      'میزبان و داوران می توانند از  <strong>Star Buzzer</strong> برای دادن 10 میلیون یا 50 میلیون رأی به افرادی که وارد آخرین مرحله شده اند  <em>(نه فقط برنده مسابقه) </em>استفاده نمایند. بدین طریق می توانند آنها را به یک star  یا Superstar تبدیل کرده و به طرف برنده شدن جایزه 100 هزار دلاری مگا استار پیش ببرند. ',
    ctmNominationWinner: 'برنده کاندید CTM',
    ctmNominationWinnerDescription:
      'میزبان و داوران این قدرت را دارند که افرادی که وارد آخرین مرحله شده اند  <em>(نه فقط برنده مسابقه) </em> را برای برنامه "مدیریت استعداد Contestee" یعنی CTM، کاندید کنند که در آن شرکت کننده های واجد شرایط تمامی پشتیبانی های مورد نیاز برای توسعه شغل خود را دریافت می کنند.  ',
    faqContesteeTitle: 'کانتستی',
    faqContestantsTitle: 'شرکت کنندگان',
    faqVoterTitle: 'رأی دهندگان',
    faqHostTitle: 'میزبانان',

    /***************************************************
     * Host Requirements Page:
     ***************************************************/
    requirementsForNewHosts: 'نیازهای ما از میزبانان جدید',
    requirementsForNewHostsDesc1:
      'در این صفحه هر آنچه که برای میزبان شدن در کانتستی لازم است شرح داده شده است. (در حال حاضر، میزبان شدن در کانتستی، برای افراد محبوب، مشهور و کمپانی های واجد شرایط در نظر گرفته شده است)',
    requirementsForNewHostsDesc2:
      'چنانچه شما به عنوان میزبان تأیید شده اید، مراحل زیر را به کمک کمپانی کانتستی کامل کنید:',
    signTheHostAgreement: '<em>قرارداد میزبان </em> را امضا کنید.',
    sendFourDocuments: 'تعداد 4 یا بیشتر عکس با کیفیت بالا را ارسال کنید.',
    chooseTheNameOfYourContestAndConsiderWhoYouWantToInviteAsAJudge:
      'نام مسابقه خود و افرادی که می خواهید به عنوان داوران مسابقه باشند را تعیین کنید.',
    forAnyCustomProductsYouWantToSellInTheContesteeMall:
      'موارد زیر را ارائه کنید:',
    contesteeMallInfo1:
      'برای هر محصولی که قصد فروش در فروشگاه کانتستی را دارید: ',
    contesteeMallInfo2: 'For any prizes you want to include in the contest:',
    productName: 'نام محصول',
    productPrice: 'قیمت محصول',
    productPhoto: 'عکس محصول',
    prizeName: 'نام جایزه',
    prizeInfo: 'اطلاعات جایزه',
    productDescription: 'توضیح محصول',
    forAnyPrizesYouWantToIncludeInTheContest:
      'برای هر جایزه ای که در مسابقه قرار می دهید: ',
    sendTheCoverPhotoForYourContest:
      'یک عکس کاور با کیفیت بالا را برای مسابقه خود ارسال کنید. ',
    highResolution: 'کیفیت بالا',
    mainSubjectOfTheCoverPhotoShouldBeOnTheRighthandSideForExample:
      'Main subject of the cover photo should be on the right-hand side, for example:',
    highlyRecommendedInformation:
      '<em>(در انجام مراحل زیر چنانچه با مشکلی برخورد کردید، پیشنهاد می شود که </em> <strong>راهنمای میزبان </strong> <em> را مطالعه کنید)</em>',
    addInfoToHostProfileSeeHostGuide:
      'اطلاعات را به پروفایل میزبان اضافه کنید. <em>(راهنمای میزبان را مطالعه کنید)</em>',
    profilePic: 'عکس پروفایل',
    bio: 'بیوگرافی',
    accomplishmentsAwards: 'دستاوردها و جوایز',
    links: 'لینک ها',
    createContestAndAddContestInfoSeeHostGuide:
      'مسابقه را ایجاد نموده و اطلاعات مسابقه را اضافه کنید. <em>(راهنمای میزبان را مطالعه کنید)</em>',
    promoteYourContestOnYourSocialMediaAndOtherPlatforms:
      'مسابقه خود را در شبکه های اجتماعی ترویج دهید.',
    hostGuideWillHelpYou:
      '<strong>راهنمای میزبان </strong> <em>کانتستی به شما در مراحل بعدی کمک خواهد کرد.</em>',
    or: 'یا',

    /***************************************************
     * Validation Messages
     ***************************************************/
    pleaseInputYourName: 'لطفا نام خود را وارد نمایید',
    pleaseEnterFirstName: 'لطفا نام خود را وارد نمایید',
    pleaseEnterLastName: 'لطفا نام خانوادگی خود را وارد نمایید',
    pleaseSelectCity: 'شهر را انتخاب کنید',
    pleaseSelectState: 'state را انتخاب کنید',
    invalidEmailAddress: 'ایمیل نامعتبر',
    pleaseInputYourEMail: 'لطفا ایمیل خود را وارد نمایید',
    pleaseInputYourQuestion: 'لطفا سوال خود را وارد نمایید',

    /***************************************************
     * Success / Error / Info Messages
     ***************************************************/
    thankYouForContactingUs: 'تشکر از شما جهت تماس با ما!',
    toManyAttemptsForResendOtp: 'تلاش بسیار زیاد برای ارسال مجدد OTP',
    otpSentSuccessfully: 'OTP با موفقیت ارسال شد',
    noMatchFound: 'تطبیقی پیدا نشد!',
    pleaseWait: 'لطفا صبر کنید ...',
    countryChangeInfo:
      'جهت تغییر کشور بایستی شماره خود را تغییر داده و آنرا تأیید نمایید',
    termAcceptInfo: 'لطفا با terms & condition و privacy policy موافقت کنید',

    /***************************************************
     * Voter Guide
     ***************************************************/
    voterGuideTitle: 'راهنمای رأی دهندگان',
    voterGuideDesc:
      'این راهنما به شما کمک می کند که حساب کاربری خود را ایجاد کرده و رأی خود را در کانتستی وارد کنید',
    voterGuideContents: 'فهرست مطالب',
    voterDownloadTheContesteeApp: 'اپلیکیشن Contestee را دانلود کنید.',
    createYourAccount: 'حساب کاربری خود را ایجاد نمایید.',
    createYourAccountDesc:
      'به محض آینکه اپلیکیشن را دانلود نمودید، می توانید حساب کاربری خود را ایجاد کنید. حساب کاربری شما بطور مستقیم به شماره تلفن شما متصل است. کسی نمی تواند بدون اجازه شما به حساب کاربریتان دسترسی داشته باشد. ',
    howToVote: 'چگونه رأی دهیم',
    howToVoteDesc:
      'حال که حساب کاربری خود را ایجاد کردید، می توانید شروع به رأی دادن کنید! می توانید با فشردن دکمه "Vote" در قسمت پایین هر پست، به هر شرکت کننده در اپلیکیشن رأی دهید، همچنین می توانید با تنظیم دکمه حرکت کننده تعداد رأی های خود را تغییر دهید. ',
    otherFeatures: 'امکانات دیگر',
    voterDownloadTheContesteeAppDesc:
      'چنانچه تا به حال اپلیکیشن Contestee را دانلود نکرده اید، می توانید آنرا بصورت رایگان دریافت نمایید از',
    appStore: 'App Store',
    googlePlay: 'Google Play',
    openTheContesteeApp: 'اپلیکیشن Contestee را باز کنید.',
    enterYourPhoneNumber: 'شماره تلفن خود را وارد کنید.',
    selectYourCountryCodeByTappingTheFlagIcon:
      'کد کشور خود را با زدن بر روی پرچم کشور انتخاب کنید.',
    tapTheArrowEnterThe_5DigitVerificationCodeSentToYourPhone:
      'کلید جهتی را فشرده و کد تأییدیه 5 رقمی که به گوشی شما ارسال شده است را وارد کنید.',
    chooseAProfilePicture: 'یک عکس برای صفحه خود انتخاب کنید.',
    enterYourName: 'نام خود را وارد کنید.',
    enterYourCity: 'شهر خود را وارد کنید.',
    enterYourEmail: 'ایمیل خود را وارد کنید.',
    tapSubmit: 'دکمه "Submit" را بزنید.',
    toEditThisInformationLater:
      'چنانچه بخواهید این اطلاعات را بعداً تغییر دهید:',
    openYourProfile: 'صفحه خود را باز کنید.',
    tapOnYourProfilePicture: 'بر روی عکس صفحه خود در بالای صفحه بزنید.',
    editAnySectionByTappingIt:
      'هر قسمتی که می خواهید را بر روی آن زده و تغییر دهید.',
    votingTicketsTitle: 'بلیط های رأی دهی',
    votingTicketsDesc:
      'هر زمانی که رأی می دهید، لازم است که بلیط های رأی دهی خود را استفاده نمایید. تعداد بلیط های شما هر روز حداکثر تعداد 10  عدد می باشد. چنانچه بخواهید بلیط های بیشتر کسب کنید لازم است که:',
    browsingContests:
      '<strong>مسابقه ها را مرور کنید</strong> – 1 بلیط برای هر 10 پست که مشاهده می کنید.',
    watchingAds:
      '<strong>تبلیغات را مشاهده کنید</strong> – 1 بلیط برای هر تبلیغی که مشاهده می کنید.',
    invitingFriends:
      '<strong>دوستان خود را دعوت کنید</strong> – 10 بلیط هر زمانیکه یک فرد با لینک دعوت شما حساب کاربری ایجاد می کند.',
    votingPowerTitle: 'قدرت رأی دهی',
    votingPowerDesc:
      'چنانچه در رأی دهی فعال باشید، به سطح بالایی از قدرت رأی دهی دست خواهید یافت. این بدان معناست که هر بلیط شما به اندازه بیشتر از یک رأی ارزش دارد.',
    theDifferentLevelsForVotingPowerAre: 'سطوح مختلف قدرت رأی دهی عباتند از:',
    level: 'سطح',
    requirement: 'پیش نیاز',
    regularTitle: 'معمولی',
    none: 'ندارد',
    vote_1_000Times: '<strong>1,000</strong> بار رأی دهید',
    vote_1_0000Times: '<strong>10,000</strong> بار رأی دهید',
    vote_100_000Times: '<strong>100,000</strong> بار رأی دهید',
    vote_1_000_000Times: '<strong>1,000,000</strong> بار رأی دهید',
    votintPowerTableDesc:
      'با رسیدن به سطح جدید از قدرت رأی دهی، تأثیر شما بر روی مسابقات بیشتر خواهد بود و نیز پاداش بیشتری از طرف پشتیبان کنندگان اپلیکیشن کسب می کنید. ',
    howToShareAPost: 'چگونه می توان یک پست را به اشتراک گذاشت؟',
    youCanShareAnyPostFromHostOrPopularityContestsByFollowingTheseSteps:
      'شما می توانید هر پستی را از مسابقات میزبانان و یا از مسابقات محبوبیت، به شیوه یکسان به اشتراک بگذارید:',
    openThePostYouWantToShare:
      'پستی را که می خواهید به اشتراک بگذارید باز کنید.',
    tapThe_3DotsOnTheRightSideOfThePost:
      'بر روی دکمه سه نقطه که در سمت راست پست قرار دارد بزنید.',
    selectShare: '"Share" را انتخاب کنید. ',
    chooseHowYouWantToShareThePost:
      'نحوه به اشتراک گذاشتن پست را انتخاب نمایید.',
    howToBookmarkAPost: 'چگونه می توان یک پست را انتخاب کرد؟',
    bookmarkSteps:
      'شما می توانید پست ها را در کانتستی با امکان نشانه گذاری کردن، ذخیره کنید. مراحل زیر را دنبال کنید:',
    openThePostYouWantToSave: 'پستی که می خواهید ذخیره کنید را باز کنید.',
    selectBookmarkPost: '" Bookmark Post" را انتخاب کنید. ',
    howToViewYourBookmarkedPosts:
      'چگونه می توان پست های انتخاب شده را مشاهده نمود؟',
    viewBookmarkedPostsTitle:
      'جهت مشاهده پست های ذخیره شده این مراحل را دنبال کنید:',
    openTheProfilesPageByTappingTheBottomRightIcon:
      'صفحه خود را با زدن بر روی علامت سمت راست پایین باز کنید.',
    openYourVoterProfile: 'صفحه رأی دهی خود را باز کنید.',
    selectSavedPosts: '"Saved Posts" را انتخاب نمایید.',
    giveFeedbackToDevelopers: 'نظریه دادن به توسعه دهندگان اپلیکیشن',
    giveFeedbackToDevelopersDesc:
      'جهت گزارش دادن مستقیم به ما در مورد ایرادهای اپلیکیشن و یا هر نظریه دیگر، این مراحل را دنبال کنید:',
    scrollToTheBottomOfTheHomePage: 'به پایین صفحه اصلی بیایید. ',
    tapMore: 'بر روی "More" بزنید.',
    selectFeedback: '"Feedback" را انتخاب کنید. ',
    sendUpTo_3ScreenshotsAndAShortMessageToOurDevelopers:
      'حداکثر 3 عکس از صفحه نمایش به همراه یک پیغام کوتاه برای توسعه دهندگان ما بفرستید.',
    viewAllContestPosts: 'مشاهده تمامی پست مسابقه',
    viewAllContestPostsDesc:
      'شما می توانید تمامی پست های یک مسابقه را از طریق یکی از پست ها در آن مسابقه مشاهده نمایید:',
    selectShowAllPosts: '"Show all Posts" را انتخاب کنید. ',
    contestStage: 'صحنه نمایش مسابقه',
    contestStageDesc:
      'در یک مسابقه میزبان، علامت های مختلفی با کاربردهای متفاوت وجود دارند، که عبارتند از:',
    announcements: 'نظرات و پیشنهادات میزبان',
    opensTheContestSAnnouncements:
      'توسط این علامت می توانید آگهی های مسابقه را مشاهده کنید. ',
    judgeFeedback: 'نظرات داور',
    judgeFeedbackDesc:
      'توسط این علامت می توانید چگونگی نظریه میزبان و داوران را در صورت وجود مشاهده کنید. ',
    contestResults: 'نتایج مسابقه',
    contestResultsDesc:
      'توسط این علامت می توانید نتایج و آمار  هر مرحله از مسابقه تا زمان کنونی که شامل شرکت کنندگان فعلی و حذف شده، آمار کسب شده و تاریخ های شروع برای هر مرحله است را مشاهده کنید. ',
    commentsVoiceClub: 'نظرات و پیام های کاربران و Voice Club',
    commentsVoiceClubDesc:
      'توسط این علامت می توانید نظرات افراد دیگر را در بخش نظرات بخوانید. شما همچنین می توانید از این قسمت به امکان  Voice Club که در آینده به اپلیکیشن اضافه خواهد شد، دسترسی داشته باشید. ',
    moreOptions: 'گزینه های بیشتر',
    showAllPosts:
      '<strong>Show All Posts (نمایش تمامی پست ها)</strong> – تمامی پست های مرحله فعلی مسابقه را نمایش می دهد. ',
    bookmarkSavePostListDesc:
      '<strong> Bookmark (انتخاب کردن)</strong> – این پست را در بخش انتخاب شده های اپلیکیشن ذخیره کنید. برای مشاهده پست های انتخاب شده لازم است که:',
    shareALinkToThisPost:
      '<strong> Share (به اشتراک گذاری)</strong> لینکی از این پست را به اشتراک بگذارید.',
    aboutContestListDesc:
      '<strong>About Contest (درباره مسابقه)</strong> – اطلاعات مسابقه را نمایش می دهد.',

    /***************************************************
     * Contestant Guide
     ***************************************************/
    contestantGuideDesc: 'راهنمای قدم به قدم برای پیوستن به مسابقه میزبانان',
    searchForHost: 'جستجو برای میزبان',
    auditionForContest: 'گزینش برای مسابقه',
    onceYouVeFinishedDownloadingOpenTheApp:
      '. به محض اتمام دانلود، اپلیکیشن را باز کنید.',
    auditionForContestDesc:
      'جهت شرکت در یک مسابقه، در ابتدا بایستی یک ویدیو در بخش گزینش بفرستید، که توسط میزبان مسابقه بررسی شده و سپس مورد قبول واقع شده و یا رد صلاحیت خواهد شد. برای هر مسابقه شما می توانید فقط یک ویدیو را برای پذیرش آپلود نمایید، چنانچه این ویدیو مورد قبول واقع شود، برای اولین مرحله مورد استفاده قرار خواهد گرفت. ویدیوی شما لازم است که برای مسابقه مناسب بوده و طول آن کمتر از 90 ثانیه باشد.',
    selectTheContestAndTapBecomeAContestant:
      'مسابقه را انتخاب کرده و بر روی "Become a Contestant" بزنید.',
    tapTheCheckboxAndThenTapNext:
      'بر روی کادر انتخابی زده و سپس بر روی "Next" بزنید.',
    reviewTheVideoRequirementsAndTapOk:
      'موارد مورد نیاز ویدیو را بررسی نموده و بر روی "OK" بزنید.',
    uploadYourVideo: 'ویدیوی خود را بارگذاری کنید.',
    addHashtags: 'هشتگ هایی را اضافه کنید.',
    searchForHostDesc:
      'حال که حساب کاربری شما تنظیم شد، مسابقه مورد نظر را انتخاب کرده و در بخش گزینش شرکت کنید. توجه داشته باشید که نمی توان در مسابقات میزبانانی که قبلا شروع شده اند شرکت کرده و فقط می توان در مسابقاتی که هنوز در مرحله پذیرش قرار دارند شرکت نمود.  ',
    searchForHostInfo1:
      'بر روی دکمه جستجو در نوار پایین صفحه، در قسمت وسط بزنید. ',
    searchForHostInfo2: 'بر روی "Hosts" بزنید. ',
    searchForHostInfo3:
      'نام میزبان برای مسابقه ای که می خواهید در آن شرکت کنید را وارد نمایید. ',
    searchForHostInfo4:
      'بر روی میزبان مورد نظر زده تا بتوانید پروفایل او را ببینید.',
    searchForHostInfo5:
      'مسابقه میزبانی که می خواهید در آن شرکت کنید را انتخاب کنید. ',
    searchForHostInfo6:
      'بر روی عکس خود زده تا بتوانید ویدیویی که می خواهید با آن در پذیرش شرکت کنید را آپلود نمایید.',

    /***************************************************
     * Host Guide
     ***************************************************/
    hostGuide: 'راهنمای میزبان',
    hostGuideDesc:
      'این صفحه راهنمایی است جهت ساخت و مدیریت پروفایل شما به عنوان میزبان Contestee',
    manageAdmins: 'مدیریت ادمین ها',
    addAnAdmin: 'اضافه کردن ادمین',
    removeAnAdmin: 'حذف کردن ادمین',
    createYourContest: 'مسابقه خود را ایجاد نمایید',
    hostPage: 'صفحه میزبان',
    winners: 'برندگان',
    turnout: 'مجموع آرا',
    followers: 'فالوورها',
    posts: 'پست ها',
    hostActions: 'فعالیت های میزبان',
    startALivestream: 'شروع پخش زنده',
    livestreamOptions: 'گزینه های پخش زنده',
    savedLivestreams: 'پخش زنده های ذخیره شده',
    accountingReport: 'گزارش حسابداری',
    promoteContest: 'تبلیغ کردن مسابقه',
    hostActionsInContestStage: 'فعالیت های میزبان در صحنه مسابقه',
    sendFeedback: 'ارسال نظر',
    starBuzzer: 'Star Buzzer',
    nominate: 'کاندید کردن',
    contestManagement: 'مدیریت مسابقه',
    auditions: 'پذیرش',
    startTheContest: 'مسابقه را شروع کنید',
    startANewRound: 'مرحله جدید را آغاز کنید',
    endTheContest: 'پایان دادن به مسابقه',
    merchandiseStore: 'فروشگاه کالا',
    enterYourBio: 'بیوگرافی خود را وارد نمایید',
    enterYourAccomplishments: 'دستاوردهای خود را وارد کنید',
    enterYourAwards: 'پاداش و جوایز اعطایی خود را وارد کنید',
    enterYourLinks: 'لینک های خود را وارد کنید',
    tap: 'برای افزودن لینک، دکمه',
    buttonToAddALink: 'را بفشارید',
    enterLinkNameExFacebookInstagramMyWebsiteEtc:
      'نام لینک را وارد کنید. (برای مثال: Facebook ، Instagram ، My Website و ...)',
    enterUrl: 'آدرس وب سایت را وارد کنید',
    tapSave: 'دکمه "Save" را فشار دهید.',
    addStore: 'فروشگاه را اضافه کنید',
    asAHostYouGetYourOwnOnlineStoreAndMerchandiseToSellInThe:
      'به عنوان یک میزبان، شما فروشگاه آنلاین و تجارت خود را خواهید داشت که می توانید اقدام به فروش کنید در',
    contesteeMall: 'Contestee mall',
    evenIfYouHaveNoMerchandiseYourselfContesteeWillCreateDesignNewCustomMerchandiseForYou:
      '. حتی چنانچه شما خودتان کالایی را برای فروش ندارید، Contestee کالای جدیدی را برای شما طراحی و ایجاد می کند.',
    ifYouHaveYourOwnProductsFirstSendTheFollowingToYourAmbassador:
      'چنانچه شما محصولات خود را دارید این موارد را برای نماینده خود ارسال کنید:',
    productPicture: 'عکس محصول',
    productDescriptionOptional: 'توضیحات محصول (اختیاری)',
    productDescriptionOptionalDesc:
      'قبل از نصب کردن فروشگاه خود، در ابتدا لازم است که یک عکس از خود با کیفیت عالی ارسال کنید. (برای cover صفحه و طراحی سفارشی محصولات)',
    allYouNeedToDoNowIsLinkYourStorePageToYourProfile:
      'هم اکنون لازم است که صفحه خود را به پروفایلتان متصل کنید:',
    goToYourProfile: 'به قسمت پروفایل خود بروید.',
    tapYourProfilePicture: 'بر روی عکس پروفایل خود بزنید.',
    scrollToTheBottom: 'به پایین صفحه بیایید.',
    tapThePlusButtonNextToStores:
      'دکمه "بعلاوه" که در کنار "Stores" قرار دارد را فشار دهید.',
    uploadYourStoreSCoverPageProvidedByContestee:
      'صفحه cover فروشگاه خود (که توسط contestee ارائه می شود) را آپلود کنید.',
    typeMyStoreForYourStoreName:
      'عبارت "My Store" را برای نام فروشگاه خود تایپ نمایید.',
    copyAndPasteYourStoreLinkExample:
      'لینک فروشگاه خود را Copy و سپس Paste کنید. به عنوان مثال:',
    storeYourName: '/store/your-name',
    manageAdminsDesc:
      'یک ادمین کسی است که برای مدیریت کردن تمامی جنبه های تکنیکی پروفایل میزبان به شما کمک می کند. به عنوان میزبان تنها موارد محدودی است که بایستی خودتان آنها را انجام دهید و بقیه موارد می تواند توسط ادمین انجام شود. ادمین بایستی فردی باشد که شما به او اعتماد دارید و می تواند جزئیات اجرای مسابقات را فرا گرفته و پروفایل شما را مدیریت کند. شما می توانید از نماینده خود بخواهید که ادمین شما شود.',
    addAdminDesc:
      'برای آنکه یک ادمین بتواند صفحه شما را مدیریت کند، لازم است که اجازه دسترسی به حساب کاربریتان را به او بدهید.',
    haveYourAdminLogInUsingYourPhoneNumber:
      'به ادمین خود اجازه دهید با استفاده از شماره شما وارد سیستم شود',
    sendThemThe_5DigitNumberYouReceive:
      'عدد 5 رقمی که دریافت می کنید را برایش ارسال کنید.',
    onceTheyReLoggedInTheyLlBeAbleToHelpManageYourPage:
      'به محض اینکه ادمین وارد سیستم شد، قادر خواهد بود که صفحه شما را مدیریت کند.',
    removeAnAdminDesc:
      'برای پس گرفتن دسترسی از یک ادمین، از تمامی سیستم ها خارج شده:',
    selectLogout: '"Logout" را انتخاب کنید.',
    selectLogoutFromAllDevices: '"Logout from all devices" را انتخاب کنید.',
    thisWillKickEveryoneOffOfYourAccountToReAccessItTheyWillRequireYouToSendThemACode:
      'از حالا، هر فردی که بخواهد مجدداً به حساب شما دسترسی داشته باشد، یک کد جدید نیاز خواهد داشت.',
    goToProfiles: 'به پروفایل بروید.',
    selectYourHostProfile: '"Host Profile" خود را انتخاب کنید.',
    tapOnHostActions: 'بر روی "Host Actions" بزنید.',
    selectWord: 'انتخاب کنید “',
    createANewContest: 'Create a New Contest',
    enterContestName: 'نام مسابقه را وارد نمایید.',
    addACoverPhoto: 'یک عکس Cover را آپلود نمایید.',
    selectTheStartDate: 'تاریخ شروع را انتخاب کنید.',
    thisIsTheDateThatAuditionsWillEndAndVotingWillBegin:
      'این تاریخی است که پذیرش پایان می یابد و رأی دهی آغاز می شود.',
    peopleWillBeAbleToAuditionAsSoonAsYouFinishCreatingTheContest:
      'به محض آنکه شما مسابقه را ایجاد کردید، مردم قادر خواهند بود پذیرش شوند.',
    addAboutContest: '"About Contest" را اضافه کنید.',
    copyAndPasteTheTextDescriptionThatWillBeProvidedForYou:
      'متن توضیحات که در اختیار شما قرار خواهد گرفت را در سایت قرار دهید.',
    forEachOfTheFollowingSectionsTapThe: ' (برای هر کدام ازموارد پایین  دکمه',
    buttonToAddInformation: ' را برای اضافه کردن اطلاعات فشار دهید.)',
    addInstructionVideosWhatToDoInEachRound:
      'ویدیوهای دستورالعمل مراحل را آپلود نمایید. ("What to do" در هر مرحله)',
    eachRoundForYourContestYouShouldUploadAVideoExplainingTheSpecificRules:
      'برای هر مرحله از مسابقه خود لازم است که ویدیویی حاوی شرح قوانین مخصوص مسابقه را آپلود نمایید.',
    forTheFirstVideoAuditionInstructionsMakeSureToCoverTheFollowing:
      'برای اولین ویدیو (برای مرحله پذیرش) مطمئن باشید که موارد زیر را شامل می شود:',
    whatTheContestIsAbout: 'موضوع مسابقه.',
    theContestRules: 'قوانین مسابقه.',
    thatVideosNeedToBeUnder_90Seconds:
      'ویدیوها لازم است که کمتر از 90 ثانیه باشند.',
    afterTheAuditionRoundEachVideoOnlyNeedsToExplainTheRulesForTheNextRound:
      'پس از مرحله پذیرش، لازم است که هر ویدیو در مورد مرحله بعد توضیح دهد.',
    itIsAlsoEncouragedToCongratulateContestantsAndWishThemLuck:
      'تبریک گفتن به شرکت کنندگان و آرزوی موفقیت برای آنان، تشویق و دلگرمی برای آنان می باشد.',
    addPrizeS: 'اضافه کردن جایزه(ها)',
    selectThisPrizeType: '"Prize Type" را انتخاب کنید.',
    service: '“Service”',
    enterThisSponsorName: '"Spnsor Name" را وارد کنید.',
    contestee: '“Contestee”',
    enterThisServiceName: '"Service Name" را وارد کنید.',
    starBuzzerWithQuotes: '“Star Buzzer”',
    enterThisPrizeUrl: 'آدرس وب سایت جایزه را وارد کنید.',
    uploadPrizePhoto: 'عکسی از جایزه را آپلود کنید.',
    tapAdd: '"Add" را فشار دهید.',
    setPerformanceEvaluationCriteria:
      'تنظیم معیار ارزیابی کارایی (Performance Evaluation Criteria)',
    theEvaluationCriteriaRepresentHowTheContestantsShouldBeJudged:
      'معیار ارزیابی نشان می دهد که چگونه شرکت کنندگان باید داوری شوند.',
    includeAtLeast_3_4DifferentCriteria:
      'حداقل 3 یا 4 معیار مختلف را شامل شود.',
    enterTheNamePercentageAndTapAdd:
      'نام و درصد را وارد کرده و "add" را فشار دهید.',
    asAnExampleInADanceCompetitionTheCriteriaMightBe_40Technique_30Performance_20MusicalityTimingAnd_10Choreography:
      'به عنوان مثال، در یک مسابقه رقصیدن، یک قانون را می توان بدین صورت تنظیم نمود: 40% تکنیک، 30% اجرا، 20% ذوق موسیقی و زمان بندی و 10% هنر رقصیدن.',
    example: 'مثالی دیگر:',
    inviteJudges: 'دعوت کردن داوران',
    youCanInviteYourTalentedFriendsOrPeersAsJudgesToHelpDecideTheWinnerAndGiveContestantsAdvice:
      '– شما می توانید دوستان با استعداد خود را دعوت کنید که برای انتخاب شخص برنده به عنوان داور کمک کنند و به شرکت کنندگان مشورت دهند.',
    enterTheirPhoneNumber: 'شماره تلفن آنها را وارد کنید.',
    tapGetUserInfo: 'بر روی "Get User Info" بزنید.',
    confirmThePhoneNumberWasCorrectAndTapAdd:
      'پس از اطمینان از صحیح بودن شماره تلفن، بر روی "add" بزنید. ',
    addEnrollmentFees: 'مبلغ Enrollment را اضافه کنید.',
    optionalTapTheSwitchToEnableFees:
      'اختیاری – جهت فعال کردن مبالغ، بر روی سوئیچ بزنید.',
    processingFeeCostToAudition: '<em>مبلغ Processing</em> - هزینه پذیرش',
    entryFeeCostToJoinIfAccepted:
      '<em>مبلغ Entry</em> – هزینه شرکت کردن در صورت قبول شدن.',
    addBillboardAdvertisementCost: 'مبلغ تبلیغات Billboard را اضافه کنید.',
    enterTheRateApprovedByContestee:
      'مبلغ ارائه شده توسط Contestee را وارد کنید.',
    tapCreate: '"Create" را بزنید.',
    ifYouWantToEditInformationLater:
      'چنانچه می خواهید این اطلاعات را بعداً ویرایش کنید:',
    goToHostProfile: 'به پروفایل میزبان بروید:',
    selectTheThreeDots: 'دکمه سه نقطه (',
    inTheTopRightCorner: ') را از قسمت بالا گوشه سمت راست انتخاب کنید.',
    tapEditButton: 'دکمه "Edit" را بزنید.',
    makeChanges: 'تغییرات را اعمال کنید.',
    scrollDownTapSave: 'به پایین صفحه آمده و "Save" را بزنید.',
    everythingVisibleToYouOnYourHostPageOtherThanHostActionsIsVisibleToThePublic:
      'تمامی مواردی که در صفحه میزبان برای شما قابل نمایش است، بجز عملیات میزبان، برای عموم قابل مشاهده خواهد بود.',
    showsHowManyPeopleHaveWonInYourContestsTapToSeeDetails:
      'تعداد افرادی که در مسابقات شما برنده شده است را نشان می دهد. برای مشاهده جزئیات فشار دهید.',
    showsHowManyTotalVotesPeopleHaveCastInAllYourContests:
      'در این قسمت شما می توانید مجموع آرا داده شده توسط مردم را ملاحظه کنید.',
    showsHowManyPeopleAreFollowingYouFollowersWillGetANotificationWheneverYouMakeAPostOrAnnouncement:
      'تعداد افرادی که شما را دنبال می کنند نمایش می دهد. هر زمانی که شما یک پست و یا یک اعلامیه را قرار می دهید، فالوورها آگاه خواهند شد.',
    toSeeYourPosts: 'برای دسترسی به پست ها:',
    openHostProfile: 'به پروفایل میزبان خود بروید.',
    tapPosts: 'بر روی "Posts" بزنید.',
    addAPost: 'اضافه کردن یک پست',
    tapThePlusIconToEither: 'برای موارد زیر بر روی آیکن بعلاوه بزنید:',
    takeAPhotoVideoOnYourCamera: 'یک عکس / ویدیو را با دوربین خود بگیرید.',
    selectUsePhoto: '"Use photo" را انتخاب کنید.',
    importAPhotoVideoFromYourPhotoGallery:
      'یک عکس / ویدیو را از گالری عکس خود وارد کنید.',
    selectChoose: '"Choose" را انتخاب کنید.',
    waitForYourUploadToComplete: 'صبر دهید تا آپلود شما انجام شود.',
    deleteAPost: 'پاک کردن یک پست',
    tapOnOneOfYourPosts: 'بر روی یکی از پست هایتان بزنید.',
    selectTheLargeDeleteButton: 'دکمه "DELETE" را انتخاب کنید.',
    asAHostYouCanStartAPublicLivestreamWhereAnyoneCanJoinAndWatchYouTalkByYourselfOrWithGuestSAllOfYourFollowersWillGetANotificationWheneverYouGoLive:
      'به عنوان یک میزبان، شما می توانید یک پخش زنده را بصورت انفرادی و یا با میهمان آغاز کنید و هر فردی می تواند به آن پیوسته و شما را مشاهده کند. زمانی که شما پخش زنده دارید، تمامی فالوورهای شما مطلع خواهند شد.',
    goToProfilesBottomRightIcon:
      'به قسمت پروفایل ها بروید (آیکن سمت راست - پایین)',
    selectLiveChat: '"Live Chat" را انتخاب کنید.',
    tapStartNow: '"Start Now" را فشار دهید.',
    duringALivestreamThereAreAFewActionsYouCanDoThroughTheButtonsAtTheBottomOfYourScreenFromLeftToRightTheOptionsAre:
      'در طول پخش زنده، یک سری عملیات را از طریق دکمه های پایین صفحه خود می توانید انجام دهید. از سمت چپ به راست گزینه ها عبارتند از:',
    commentSendACommentForAllYourViewersToSee:
      '<strong>Comment</strong> - ارسال یک اظهار نظر برای تمامی بینندگانتان.',
    questionsAnswerQuestionsFromYourViewers:
      '<strong>Questions</strong> - پاسخگویی به سوالات بینندگانتان.',
    muteTapToMuteOrUnmuteYourMicrophone:
      '<strong>Mute</strong> - بی صدا یا صدا دار کردن میکروفون.',
    cameraSwitchBetweenYourFrontAndRearCameras:
      '<strong>Camera</strong> - تغییر بین دوربین های جلو و پشت گوشی.',
    addLive:
      '<strong>Add Live</strong> - اضافه کردن کاربر دیگر برای پیوستن به شما از طریق ویدیو (در این صورت صفحه نمایش با آنها تقسیم خواهد شد). یا اینکه می توانید نام آنها را جستجو کرده و "Add" را بزنید یا اینکه آنها را از طریق درخواست آنها اضافه کنید.',
    emojiSendAnEmojiAcrossTheScreen:
      '<strong>Emoji</strong> – ارسال یک emoji بر روی یک صفحه.',
    allOfYourLiveVideosAreAutomaticallySavedToYourHostPageForAnyoneToWatch:
      'تمامی ویدیوهای زنده شما بصورت خودکار در صفحه میزبان ذخیره می شوند، برای آنکه هر فردی بتواند آنها را مشاهده کند.',
    toAccessYourLivestreamVideos: 'جهت دسترسی به ویدیوهای پخش زنده:',
    goToYourHostPage: 'به صفحه میزبان خود خود بروید.',
    selectPosts: 'پست ها را انتخاب کنید.',
    selectYourLiveVideosArchive: '"Live Videos Archive" خود را انتخاب کنید.',
    accountingReportsDesc:
      'در حال حاضر گزارشات حسابداری در دسترس نیستند و در نسخه های بعدی ارائه خواهد شد.',
    promoteContestDesc:
      'مهم است که قبل از شروع مسابقه، آنرا تبلیغ کرده و به اشتراک بگذارید. جهت دریافت لینکی از مسابقه خود یا برای به اشتراک گذاری آن در دیگر اپلیکیشن ها، این مراحل را دنبال کنید:',
    goToYourHostProfile: 'به "پروفایل میزبان" خود بروید.',
    tapOnTheContest: 'بر روی مسابقه بزنید.',
    selectShareContestLink: '"Share Contest Link" را انتخاب کنید.',
    theContestStageIsWhereAllYourContestantSPostsCanBeSeenToQuicklyAccessYourContestStage:
      'صحنه نمایش مسابقه جایی است که تمامی پست های شرکت کننده را می توان مشاهده نمود. برای دسترسی سریع به صحنه نمایش مسابقه:',
    goToYourHostProfile1: 'به "پروفایل میزبان" خود بروید.',
    openYourContest: 'مسابقه خود را باز کنید.',
    goToTheContestantsPage: 'به صفحه شرکت کنندگان بروید.',
    selectViewContestStage: '"View Contest Stage " را انتخاب کنید.',
    thereAreSeveralOptionsThatAnyoneCanSeeOnTheContestStageFromTopToBottomTheseOptionsAre:
      'چندین گزینه وجود دارند که هر کس می تواند در صفحه مسابقه ببیند. این گزینه ها از بالا تا پایین عبارتند از:',
    whenYouReLookingAtAnyPostInYourContestStageYouHaveSeveralUniqueHostActionsInAdditionalToThePreviousOnes:
      'زمانی که شما به هر پستی در صحنه نمایش مسابقه خود نگاه می کنید، علاوه بر عملیات قبلی، یک سری عملیات منحصر به فرد دیگر نیز دارید.',
    voteAsAHost: 'رأی دادن به عنوان میزبان',
    unlikeVotingNormallyVotingAsAHostIsPublicAndEachVoteIsWorth_100Points:
      'بر خلاف رأی دادن معمولی، رأی دادن به عنوان یک میزبان به صورت عمومی انجام می گیرد و هر رأی 100 امتیاز حساب می شود.',
    giveFeedback: 'ارسال نظر',
    sendAFeedbackResponseToTheContestantWhereYouCanGiveThemPraiseCriticismAndAdviceOnHowToImprove:
      'ارسال یک نظر به شرکت کننده، که می توانید از آنها تمجید و یا انتقاد کرده و یا پیشنهادی برای پیشرفت او بدهید.',
    enabledAfterTheContestStarts:
      '(این امکان پس از شروع مسابقه قابل استفاده می باشد)',
    giveTheContestWinnerEither_10MillionOr_50MillionVotes:
      'اعطای 10 میلیون و یا 50 میلیون رأی.',
    onlyUseableOnContestFinalistsAfterTheFinalRoundEnds:
      '(قابل استفاده برای برنده مسابقه پس از پایان مرحله آخر)',
    nominateDesc:
      'کاندید کردن شرکت کننده برای CTM (برنامه مدیریت استعداد Contestee). افراد کاندید شده بصورت خودکار واجد شرایط نیستند تا اینکه توسط هیئت بازنگری حرفه ای مورد بررسی قرار گیرد.',
    nextRound: 'مرحله بعدی',
    endTheCurrentRoundOfTheContestToStartANewRoundInTheContest:
      'پایان دادن مرحله فعلی برای شروع مرحله جدید مسابقه.',
    liveChat: 'گپ و گفتگوی زنده',
    shareContestLink: 'به اشتراک گذاری لینک مسابقه',
    shareALinkToThisContest: 'لینک مسابقه را به اشتراک بگذارید.',
    editContestInfo: 'ویرایش اطلاعات مسابقه',
    openTheContestInfoPanelToMakeChanges:
      'قسمت اطلاعات مسابقه را برای اعمال تغییرات باز کنید.',
    contestManagementDesc:
      'مهمترین جنبه میزبان بودن دانستن این موضوع است که چگونه یک مسابقه را شروع، اجرا و به پایان برسانید.',
    auditionsDesc:
      'به محض اینکه مسابقه خود را ارائه می کنید، این مسابقه قابل مشاهده خواهد بود. مردم قادر خواهند بود که برای تبدیل شدن به شرکت کننده پذیرش شوند. قبل از شروع مسابقه، فقط شما قادر خواهید بود که ویدیوهای پذیرش را مشاهده نمایید.',
    selectTheContestViewTheAuditionsPage:
      'contest view  صفحه پذیرش را انتخاب کنید.',
    tapAnAuditionVideoToWatchIt:
      'بر روی ویدیوی پذیرش زده تا بتوانید آنرا مشاهده کنید.',
    acceptReject: 'جهت تایید، رد کردن و یا حذف پذیرش تصمیم بگیرید.',
    acceptReject1:
      '<strong>Accept  /  پذیرش </strong> - فرد با استعداد یک شرکت کننده خواهد شد. ویدیوی آنها بصورت عمومی قابل مشاهده خواهد بود.',
    acceptReject2:
      '<strong>Reject / رد کردن </strong> - فرد با استعداد یک شرکت کننده نخواهد شد. ویدیوی آنها بصورت عمومی قابل مشاهده خواهد بود.',
    acceptReject3:
      '<strong>Delete / حذف کردن </strong> - فرد با استعداد یک شرکت کننده نخواهد شد. ویدیوی آنها بصورت عمومی قابل مشاهده نخواهد بود.',
    important: 'مهم!!!',
    makeSureToDeleteInappropriateVideosDoNotRejectThem:
      'مطمئن شوید که ویدیوهای نامناسب را پاک می کنید. آنها را رد نکنید.',
    onceTheStartDateHasArrivedAndYouHaveReviewedAllTheAuditionsTheContestCanBegin:
      'به محض رسیدن به تاریخ شروع  و بررسی تمامی پذیرش ها، مسابقه را می توان شروع نمود.',
    selectStartContest: '"Start Contest" را انتخاب کنید.',
    onceYourContestHasBegunYouCanFollowTheseStepsToEndTheCurrentRoundAndStartRound_2:
      'به محض اینکه مسابقه خود را شروع کردید، برای پایان دادن به مرحله فعلی، موارد زیر را دنبال نموده و مرحله دوم را شروع کنید.',
    goToTheContestStage: 'به صحنه نمایش مسابقه بروید.',
    tapHostActions: 'بر روی "Host Actions" بزنید.',
    selectNextRound: '"Next Round" را انتخاب کنید.',
    startANewRoundDesc:
      'تعداد شرکت کننده هایی که می خواهید از مرحله بعد حذف کنید را مشخص نمایید (شرکت کننده های باقی مانده به مرحله بعد منتقل خواهند شد). شرکت کنندگان بر اساس اینکه چه تعداد رأی دارند، بصورت خودکار حذف می شوند.',
    tapDoneToLockInTheRemainingContestants:
      'بر روی "Done" زده تا اینکه بقیه شرکت کننده ها نتوانند کاری انجام دهند.',
    startingTheFinalRoundForAContestIsTheSameAsStartingANewRoundTheKeyDifferenceComesOnceYouWantToEndTheContest:
      'شروع آخرین مرحله برای یک مسابقه همانند شروع یک مرحله جدید می باشد. تفاوت اصلی جایی است که شما می خواهید مسابقه را پایان دهید:',
    whenYouWantToEndTheFinalRoundEliminateAllContestantsExceptForOne:
      'زمانیکه می خواهید آخرین مرحله را پایان دهید، تمامی شرکت کننده ها را بجز یک نفر حذف کنید.',
    forExampleIfThereAre_10ContestantsLeftYouShouldEliminate_10_1_9Contestants:
      'به عنوان مثال، چنانچه تعداد 10 نفر شرکت کننده باقی مانده اند لازم است که 9 شرکت کننده حذف شوند. (1 = 9 - 10)',
    afterPressingDoneTheWinnerBecomesOfficialAndYouCanSelectWhetherToGiveThemA:
      'پس از فشردن دکمه "Done"، برنده مسابقه بصورت رسمی اعلام می شود و شما می توانید به آنها بدهید',
    starOrSuperstarBuzzer: 'Star یا Superstar buzzer.',
    starOrSuperstarBuzzerDesc:
      'به محض پایان دادن یک مسابقه، این مسابقه در صفحه شما به عنوان "Finished" علامت دار می شود. رأی دهنده ها هنوز قادر خواهند بود که مسابقه را باز کرده، نتایج و ویدیوهای مسابقه را مشاهده نمایند.',
    evenIfYouHaveNoMerchandiseYourselfContesteeWillCreateDesignNewMerchandiseForYou:
      '. حتی اگر کالاهای خود را ندارید، Contestee، کالاهای جدیدی را برای شما طراحی و ایجاد خواهد کرد.',
    ifYouHaveYourOwnProductsFirstSendTheFollowing:
      'چنانچه محصولات خود را دارید، در ابتدا موارد زیر را ارسال نمایید:',
    productInfo:
      'قبل از آنکه فروشگاه شما راه اندازی شود، در ابتدا یک عکس با کیفیت خوب ارسال نمایید. (برای صفحه cover و محصولاتی که به صورت سفارشی طراحی شده است)',
  },
}

export default fa
