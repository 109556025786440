/**
 * * * * * * * * * * * * * * * * * * * *
 * TYPES - Global
 * * * * * * * * * * * * * * * * * * * *
 */

export const LOGGED_IN = 'LOGGED_IN'
export const LOG_OUT = 'LOG_OUT'
export const SET_LEFT_MARGIN = 'SET_LEFT_MARGIN'
export const SET_LANGUAGE = 'SET_LANGUAGE'

/**
 * * * * * * * * * * * * * * * * * * * *
 * TYPES - Contests
 * * * * * * * * * * * * * * * * * * * *
 */
export const FETCH_ALL_CONTESTS = 'FETCH_ALL_CONTESTS'
export const FETCH_POST_PROFILE = 'FETCH_POST_PROFILE'
export const FETCH_ALL_FILTERS = 'FETCH_ALL_FILTERS'
export const UPDATE_USER = 'UPDATE_USER'
export const GET_CONTEST_BANNER = 'GET_CONTEST_BANNER'
export const FETCH_EXPLORE_POSTS = 'FETCH_EXPLORE_POSTS'
export const FETCH_USER_CONTESTS = 'FETCH_USER_CONTESTS'
export const PRIZE_AND_REWARD = 'PRIZE_AND_REWARD'
