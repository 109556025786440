// duration for message component in seconds
export const toastDuration = 5

// in MB
export const maxUploadSize = 15

export const stripeTestPublishableKey =
  'pk_test_51HOyxbHxJjsZSFTkBMyjPRk4gmgAgKcMlD1UjsitsMByeGMqCRz0vom46Ok9mln69w3s2pOVQwcY2zlOI5VXVZvo00rGPSREdJ'

export const stripeLivePublishableKey =
  'pk_live_51HOyxbHxJjsZSFTk25RdQ9lKCVO7lfNjDHVr7XJBLZ1P1Jhx2sz5lpfkwpndWr14IZI3NJu7ExBCy3BGskl2GIXT00XsJA8cSE'

export const prodAPIURL = 'https://prod-api.contestee.com/api/'

export const routePaths = {
  home: '/home',
  postDetail: '/post-detail',
  postDetailSettings: '/post-detail-settings',
  contestantLocationRanking: '/contestant-location-ranking',
  contestantAdvertisement: '/contestant-advertisement',
  hosts: '/hosts',
  search: '/search',
  tickets: '/tickets',
  profile: '/profile',
  explore: '/explore',
  becomeContestant: '/become-contestant',
  becomeSponsor: '/become-sponsor',
  becomeAdvisor: '/become-advisor',
  becomeHost: '/become-host',
  celebrities: '/celebrities',
  contestInfo: '/contest-info',
  createContest: '/create-contest',
  contestantDetails: '/contestant-details',
  contestantStatus: '/contestant-status',
  hostDetails: '/host-details',
  testPlayer: '/test-player',
  contestsVoted: '/contests-voted',
  contestantsVoted: '/contestants-voted',
  postEvaluation: '/post-evaluation',
  howItWorks: '/how-it-works',
  savedPost: '/saved-post',
  approveApplicant: '/approve-applicant',
  editProfile: '/edit-profile',
  hostPostDetail: '/host-post-detail',
  hostPostWinners: '/host-post-winners',
  billBoardPost: '/bill-board-post',
  contestant: '/contestant',
  talentContestant: '/talent-contestant',
  talentProfile: '/talent-profile',
  judgeDetails: '/judge-details',
  contestants: '/contestants',
  popularContests: '/popular-contests',
  activeVoters: '/active-voters',
  turnouts: '/turnouts',
  privacyPolicy: '/privacy-policy',
  hostRequirements: '/host-requirements',
  sponsor: '/advertise',
  contestantGuide: '/contestant-guide',
  voterGuide: '/voter-guide',
  hostGuide: '/host-guide',
  termsAndCondition: '/terms-and-condition',
  contactUs: '/contact-us',
  prizes: '/prizes',
  eula: '/EULA',
  signFile: '/sign-file',
  billboard: '/billboard',
  winnerList: '/winner-list',
  talentContest: '/talent-contest',
  ActiveHost: '/active-hosts',
  ContestPost: '/contest-post',
  root: '/',
  notFound: '*',
}

export const generateRandomNumber = () => {
  const arrSortBy = ['_id', 'votes', 'posts', 'voters', 'lastPostTime']

  const RandomNumber = Math.floor(Math.random() * arrSortBy.length)

  const RandomNumberDir = Math.floor(Math.random() * 2)

  console.log(
    '________________ RandomNumber__________________',
    RandomNumber,
    RandomNumberDir
  )

  return {
    sortBy: arrSortBy[RandomNumber],
    sortDir: RandomNumberDir === 0 ? -1 : 1,
  }
}
