import { responsiveArray } from 'antd/lib/_util/responsiveObserve'

import Axios, { axiosInstance } from '../../config/axios.config'
import { handleAPIError } from '../../config/globalFunction'
import store from '../store'
import {
  FETCH_ALL_CONTESTS,
  FETCH_ALL_FILTERS,
  PRIZE_AND_REWARD,
  SET_LEFT_MARGIN,
  UPDATE_USER,
} from '../types'

const storeInstance = store()

// Axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log('error401', error, error.response)
//     const { status } = error.response
//     if (status === 401) {

//     }
//     return Promise.reject(error)
//   }
// )

export const setPrizeAndReward = () => async (dispatch) => {
  dispatch({
    type: PRIZE_AND_REWARD,
    payload: true,
  })
}

export const getAllContests = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('contests/get-all', data)
    if (response.data) {
      dispatch({
        type: FETCH_ALL_CONTESTS,
        payload: response.data.data,
      })
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getContestList = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: '/contests/get-all',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getPosts = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/posts',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

// export const getProfile = (params) => async (dispatch) => {
//   try {
//     const response = await Axios.get('posts/get-profile', { params })
//     if (response.data) {
//       const idsArray = response.data.data.map((item) => item._id)
//       params._ids = idsArray.join()

//       const postList = await Axios.get('posts/get-profile', { params })

//       if (postList.data) {
//         const postIdsArray = postList.data.data.map(
//           (item) => item.postIds[0]._id
//         )
//         const postDetail = await Axios.get('posts', {
//           params: {
//             _ids: postIdsArray.join(),
//           },
//         })
//         if (postDetail.data) {
//           dispatch({
//             type: FETCH_POST_PROFILE,
//             payload: {
//               profiles: response.data.data,
//               postList: postList.data.data,
//               postDetail: postDetail.data.data,
//             },
//           })
//           return {
//             profiles: response.data.data,
//             postList: postList.data.data,
//             postDetail: postDetail.data.data,
//           }
//         } else {
//           return {}
//         }
//       } else {
//         return {}
//       }
//     }
//     return response
//   } catch (error) {
//     handleAPIError(error, dispatch)
//     return error.response
//   }
// }

export const getProfile = (params, contestId) => async (dispatch) => {
  try {
    const response = await Axios.get('posts/get-profile', { params })
    if (response.data) {
      const postDetailObj = []
      response.data.data.filter((item) => {
        if (item.lastPostInfo[0]._id === contestId) {
          postDetailObj.push(item)
        }
        return null
      })
      response.data.data.map((contest) => {
        if (contest.lastPostInfo[0]._id !== contestId) {
          postDetailObj.push(contest)
        }
        return null
      })
      return postDetailObj
    }
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}
export const getAllFilters = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('contests/filters', { params })
    if (response.data) {
      const sortedArray = response.data.data.sort(
        (a, b) => a.genreInfo.displayOrder - b.genreInfo.displayOrder
      )
      dispatch({
        type: FETCH_ALL_FILTERS,
        payload: sortedArray,
      })
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const updateUser = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'PATCH',
    url: '/users',
    data,
  })
  if (response.data) {
    dispatch({
      type: UPDATE_USER,
      payload: response.data.data,
    })
    return response.data
  }
  return response

  // try {
  //   const response = await Axios.patch('users', data)
  //   if (response.data) {
  //     dispatch({
  //       type: UPDATE_USER,
  //       payload: response.data.data,
  //     })
  //     return response.data
  //   }
  //   return response
  // } catch (error) {
  //   handleAPIError(error, dispatch)
  //   return error.response
  // }
}

export const getContestBanner = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/contests/list',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
  // try {
  //   const response = await Axios.get('contests/list', { params })
  //   if (response.data) {
  //     dispatch({
  //       type: GET_CONTEST_BANNER,
  //       payload: response.data.data,
  //     })
  //     return response.data
  //   }
  //   return response
  // } catch (error) {
  //   handleAPIError(error, dispatch)
  //   return error.response
  // }
}

export const getUser = () => async (dispatch) => {
  const { userInfo } = storeInstance.getState().auth
  try {
    const response = await Axios.get('users')
    if (response.data) {
      if (JSON.stringify(userInfo) !== JSON.stringify(response.data.data)) {
        dispatch({
          type: UPDATE_USER,
          payload: response.data.data,
        })
      }

      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getUserTickets = () => async (dispatch) => {
  try {
    const response = await Axios.get('users')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getActiveVoters = () => async (dispatch) => {
  try {
    const response = await Axios.get('tickets/count')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getPopularTalents = () => async (dispatch) => {
  try {
    const response = await Axios.get('posts/get-profile-count')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getAllExplorePosts = () => async (dispatch) => {
  try {
    const response = await Axios.get('superadminconfiguration')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getAllStory = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('hosts', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getStoryData = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('story')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getCountryByContestId = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('posts/get-profile-count-by-country', {
      params,
    })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getLanguageByContestId = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('posts/languages', {
      params,
    })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const postBookmark = (postId, data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: `posts/${postId}/like-unlike-bookmark-unbookmark`,
    data,
  })
  console.log(response)
  if (response.data) {
    return response.data
  }
  return response
}

export const contestantVote = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: 'vote',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getPresignedUrl = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/utils/pre-signed-url',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
  // try {
  //   const response = await Axios.get('utils/pre-signed-url', { params })
  //   if (response.data) {
  //     return response.data
  //   }
  //   return response
  // } catch (error) {
  //   handleAPIError(error, dispatch)
  //   return error.response
  // }
}

export const billBoard = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'billboard',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const createBillBoard = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: 'billboard',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getVotingPowers = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'tickets/list',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const becomeHost = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: 'hosts',
    data,
  })
  return response
}

export const signContract = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'PUT',
    url: 'hosts/sign-contract',
    data,
  })
  return response
}

export const getNewProfiles = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/posts/get-profile',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const searchContestant = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('users/search-contestant', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getHashTags = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'posts/languages',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getContestPost = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'posts',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getUserContest = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'posts/get-profile',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const searchLanguageCountry = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'posts',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

/**
 * Become a contestant
 */
export const createPost = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: 'posts',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const addVote = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: 'vote',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const notifications = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'notifications',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const notificationCount = () => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'notifications/unread',
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getCommunityList = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'community/list',
    params,
  })

  if (response.data) {
    return response.data
  }
  return response
}

export const userFollowingData = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'users/followings',
    params,
  })

  if (response.data) {
    return response.data
  }
  return response
}

export const getCelebritiesData = (params) => async (dispatch) => {
  try {
    if (params && params.genres) {
      const celebritiesData = params.genres
      await Promise.all(
        params.genres.map(async (gener, index) => {
          const celebritiesParams = {
            skip: 0,
            limit: 100,
            sortBy: 'host.displayOrder',
            sortDir: 1,
            state: ['CONTRACT_SIGNED'],
            communityId: params._id,
            communityGenreId: gener.id,
          }
          const response = await Axios.get('hosts', {
            params: celebritiesParams,
          })
          if (response.data) {
            //console.log('Ters', response.data)
            celebritiesData[index]['celebrities'] = response.data
          }
        })
      )
      return celebritiesData
    }
  } catch (error) {
    console.log(error)
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getUserProfile = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/posts/get-profile',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getTalentEvalutor = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/contests/talent-evaluator-profiles',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getHostProfile = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'hosts',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getContestDetails = (contestId) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: `/contests/${contestId}`,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const redeemTicket = () => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: 'tickets/redeem',
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const startContest = (contestId, params) => async (dispatch) => {
  try {
    const response = await Axios.patch(`contests/${contestId}`, params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getProfileCountByCountry = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('posts/get-profile-count-by-country', {
      params,
    })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getTalentData = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('posts/get-profile', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getOtherGenerData = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('contests/list', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const voteGivenInContest = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('vote/contests', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const voteDetails = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('vote', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const contactUs = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('contact', data)
    return response.data
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const setThemeLeftMargin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LEFT_MARGIN,
      payload: data,
    })
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const acceptRejectPost = (postId, data) => async (dispatch) => {
  try {
    const response = await Axios.put(`posts/${postId}/accept-or-reject`, data)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const deletePostAsHost = (postId) => async (dispatch) => {
  try {
    const response = await Axios.delete(`posts/${postId}/as-host`)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const readNotification = (notificationId) => async (dispatch) => {
  try {
    const response = await Axios.patch(`notifications/${notificationId}/`)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const userFollowUnFollow = (url, data) => async (dispatch) => {
  try {
    const response = await Axios.post(url, data)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const checkVote = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('vote/check', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const eliminateUser = (params) => async (dispatch) => {
  try {
    const response = await Axios.put('vote/eliminate', params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const enableVotingPower = (contestId, params) => async (dispatch) => {
  try {
    const response = await Axios.put(`contests/${contestId}`, params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const deleteAllNotification = (params) => async (dispatch) => {
  try {
    const response = await Axios.delete('notifications/multi', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const billBoardPostAccept = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'PUT',
    url: '/billboard/accept-or-reject',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const searchUser = (params) => async () => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/users/search',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const voteByJudge = (params) => async (dispatch) => {
  try {
    const response = await Axios.post('/vote/vote-by-judge', params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const evalution = (postId, params) => async (dispatch) => {
  try {
    const response = await Axios.patch(`evaluations/${postId}`, params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const evalutionPosts = (hostPostId, params) => async (dispatch) => {
  try {
    const response = await Axios.get(`evaluations/${hostPostId}`, { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const createContest = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: '/contests',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const updateContest = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'PATCH',
    url: `/contests/${params.constestId}`,
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const commentList = (contestId, params) => async (dispatch) => {
  try {
    const response = await Axios.get(`/contests/comment/${contestId}`, {
      params,
    })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const announcementList = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('/announcement', {
      params,
    })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const addComment = (contestId, params) => async (dispatch) => {
  try {
    const response = await Axios.post(`/contests/comment/${contestId}`, params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const deleteComment = (contestId) => async (dispatch) => {
  try {
    const response = await Axios.delete(`/contests/${contestId}/comment`)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const deleteAnnouncement = (announcementId) => async (dispatch) => {
  try {
    const response = await Axios.delete(`/announcement/${announcementId}`)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const addAnnouncement = (params) => async (dispatch) => {
  try {
    const response = await Axios.post('/announcement', params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const likeUnlikeComments = (commentId, url, params) => async (
  dispatch
) => {
  try {
    const response = await Axios.post(
      `/contests/${url}?commentId=${commentId}`,
      params
    )
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getHostPosts = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('/live-stream/list', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const genericContestList = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('/contests/list', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getUserDetails = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/users',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const updateUserDetails = (params, userId) => async (dispatch) => {
  try {
    const response = await Axios.patch(`/users?userId=${userId}`, params)
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const userFollower = (params) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'GET',
    url: '/users/followers',
    params,
  })
  if (response.data) {
    return response.data
  }
  return response
  // try {
  //   const response = await Axios.get('users/followers', { params })
  //   if (response.data) {
  //     return response.data
  //   }
  //   return response
  // } catch (error) {
  //   handleAPIError(error, dispatch)
  //   return error.response
  // }
}

// export const removePost = (post_id) => async (dispatch) => {
//   try {
//     const response = await Axios.delete(`posts/${post_id}`)
//     if (response.data) {
//       return response.data
//     }
//     return response
//   } catch (error) {
//     handleAPIError(error, dispatch)
//     return error.response
//   }
// }

export const removePost = (post_id) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'DELETE',
    url: `posts/${post_id}`,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const getPopularTalent = (params) => async (dispatch) => {
  try {
    const response = await Axios.get('posts/badge-wise', { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getEvalutionPost = (params, contestId) => async (dispatch) => {
  try {
    const response = await Axios.get(`evaluations/${contestId}`, { params })
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getContestCount = () => async (dispatch) => {
  try {
    const response = await Axios.get('contests/counts')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getUsersCount = () => async (dispatch) => {
  try {
    const response = await Axios.get('users/counts')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const goldenBuzzerApiCall = () => async (dispatch) => {
  try {
    const response = await Axios.post('vote/golden-buzzer')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const changeUserPhone = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: '/users/phone',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
  // try {
  //   const response = await Axios.post('users/phone', data)
  //   if (response.data) {
  //     return response.data
  //   }
  //   return response
  // } catch (error) {
  //   handleAPIError(error, dispatch)
  //   return error.response
  // }
}

export const verifyOtpOnUpdatePhone = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: '/users/verify-otp-on-update-phone',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const updateHosts = (data) => async () => {
  const response = await axiosInstance({
    method: 'PATCH',
    url: '/hosts',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const addNewPost = (data) => async () => {
  const response = await axiosInstance({
    method: 'POST',
    url: '/live-stream/save-post',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}

export const isPaymentFreeCountry = () => async (dispatch) => {
  try {
    const response = await Axios.get('superadminconfiguration')
    if (response.data) {
      return response.data
    }
    return response
  } catch (error) {
    handleAPIError(error, dispatch)
    return error.response
  }
}

export const getAllContestFilter = (data) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'POST',
    url: 'contests/get-all',
    data,
  })
  if (response.data) {
    return response.data
  }
  return response
}
