import React from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setThemeLeftMargin } from '../../redux/actions/contests.action'
import Header from '../Header'
// import Footer from '../Footer'
export const headerMenuRef = React.createRef()

const Layout = (props) => {
  const dispatch = useDispatch()
  const themeLeftMargin = useSelector((state) => state.contests.leftMargin)
  const [leftMargin, setLeftMargin] = React.useState(themeLeftMargin)
  const location = useLocation()
  const { i18n } = useTranslation()

  React.useEffect(() => {
    if (headerMenuRef.current.getBoundingClientRect().left > 0) {
      dispatch(
        setThemeLeftMargin(headerMenuRef.current.getBoundingClientRect().left)
      )
      setLeftMargin(headerMenuRef.current.getBoundingClientRect().left)
    }
  }, [dispatch])

  return (
    <>
      <div className="mob-landscape-disable">
        App is not available in landscape view. Continue browsing in portrait
        mode.
      </div>
      <div
        id="wrapper"
        className={`webpage ${
          location.pathname === '/post-detail'
            ? 'post-detailpage'
            : '' || location.pathname === '/hosts'
            ? 'hosts-page'
            : '' || location.pathname === '/posts'
            ? 'posts-page'
            : '' || location.pathname === '/search'
            ? 'search-page'
            : '' || location.pathname === '/profile'
            ? 'profile-page'
            : ''
        } ${i18n.language === 'fa' ? 'RTL' : ''}`}
      >
        <Header ref={headerMenuRef} {...props.children.props} />
        {React.cloneElement(props.children, { leftMargin })}
        {/* <Footer /> */}
      </div>
    </>
  )
}

export default Layout
