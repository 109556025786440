let en = {
  translations: {
    /***************************************************
     * Landing Page: Menu
     ***************************************************/
    home: 'Home',
    aboutUs: 'About Us',
    ctm: 'CTM',
    becomeHost: 'Become Host',
    grandPrize: 'Grand Prize',
    certificates: 'Certificates',
    becomeAmbassador: 'Become Ambassador',
    features: 'Features',
    whyContestee: 'Why Contestee',
    app: 'App',
    howItWorks: 'How It Works?',
    faqs: 'FAQs',
    contactUs: 'Contact Us',
    login: 'Login',
    prizes: 'Prizes',
    mall: 'Mall',
    advertiseWithUs: 'Advertise with Us',
    userGuides: 'User Guides',
    voterGuide: 'Voter Guide',
    contestantGuide: 'Contestant Guide',
    /**
     * Landing Page: Slider
     */
    downloadTheContesteeApp: 'Sign up on the Contestee app',
    uploadVideosShowcasingYourTalent: 'Upload videos showcasing your talent',
    doYouWantToBeFamous: 'The Global Talent Discovery Platform',
    earnVotes: 'Earn votes',
    winAContestAndBecomeFamous: 'Win a contest and become famous!',
    /**
     * Landing Page: Main Contents
     */
    step: 'Step',
    ourHosts: 'Our Hosts',
    ourContests: 'Our Contests',
    ourContestsDetail:
      'Contestee features endless contests in dozens of different categories.',
    whoIsTheBest: 'Who Is The Best',
    whoIsTheMost: 'Who Is The Most',
    statisticsSoFar: 'Contestee Statistics',
    statisticsSoFarContent:
      'Contestee is on track to become the largest talent discovery platform in the world.',
    contests: 'Contests',
    contestants: 'Contestants',
    users: 'Users',
    voters: 'Voters',
    hosts: 'Hosts',
    rapper: 'Rapper',
    athlete: 'Athlete',
    fit: 'Fit',
    model: 'Model',
    cook: 'Cook',
    singer: 'Singer',
    gamer: 'Gamer',
    dancer: 'Dancer',
    talentedKid: 'Talented Kid',
    makeupArtist: 'Makeup Artist',
    graffitiArtist: 'Graffiti Artist',
    actor: 'Actor',
    talentedPet: 'Talented Pet',
    fashionDesigner: 'Fashion Designer',
    photographer: 'Photographer',
    painter: 'Painter',
    musician: 'Musician',
    whatIsContestee: 'What Is Contestee?',
    whatIsContesteeContent:
      'In English, “contestee” means someone who is competing in a contest. The Contestee App is a social network with the goal of Global Talent Discovery through competition. On Contestee, anyone around the world can showcase their incredible talents, skills, and attributes with the tap of a button. Talents can upload their videos, collect votes, and become famous!',
    watchVideo: 'Watch Video',
    /**
     * Landing Page: Main Contents - Components Of The Contestee App
     */
    componentsOfTheContesteeApp: 'Contestee Users',
    contestantsComponentDescription:
      'Contestee serves three different kinds of users for each of the three different roles in the contests:',
    componentSliderContent:
      'Audition to join a Host Contest and win for your chance to become a Megastar, or simply upload videos of your talent to a Popularity Contest to earn votes from people around the world and become famous!',
    votersComponentDescription:
      'Watch and vote for the top videos from your favorite talents to help them win their contests. Vote everyday to increase your voting power and even win prizes for reaching new thresholds.',
    hostsComponentDescription:
      'As a popular influencer, celebrity, or company you have the opportunity to host your own digital contests where tomorrow’s top talents can compete for the chance to gain incredible exposure, win the contest, and build new careers!',
    votingTickets: 'Voting Tickets',
    votingTicketsComponentDescription:
      'Earn tickets by using the app and cast your votes to help your favorite contestants win. Regular tickets = 1 vote Gold tickets = 3 votes Platinum tickets = 5 votes',
    talentAdvisors: 'Talent Advisors',
    talentAdvisorsComponentDescription:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero',
    talentEvaluators: 'Talent Evaluators',
    talentEvaluatorsComponentDescription:
      'Simply upload videos showcasing your talent, skill, or attribute. Get votes from people around the world to become famous and win contest prizes.',
    learnHowContesteeWorks: 'How Contestee Works',
    typesOfContestsInContestee:
      "In real life, Talents are judged by two factors: how popular they are, and how skilled or talented they actually are. That's why there's two types of contests in Contestee:",
    popularityContestsDescription:
      '<strong>Popularity Contests</strong> are more like social media, where any talent can join for free simply by uploading a video of their talent in order to earn votes. Earn enough votes to reach new Popularity Levels and win prizes!',
    hostContestsDescription:
      '<strong>Host Contests</strong> are more like TV-style Contests, where you first have to audition and be accepted by the Host & Judges. From there, contestants have to earn enough votes from followers to make it through each round and win the final round. The host can also press the Star Buzzer to instantly give the contest winner either 10 million or 50 million votes, taking them closer to the Megastar Prize!',
    watchContestVideo: 'Watch Contest Video',
    watchHostContestVideo: 'Watch Host Contest Video',
    watchVoterVideo: 'Watch Voter Video',
    learnHowContesteeWorksDesc1:
      'If you reach 100M total votes, you become a <strong>Megastar Talent</strong> and win the $100,000 grand prize! ',
    learnHowContesteeWorksDesc2:
      "On top of this, you also become eligible for <strong>Contestee Talent Management</strong> <em>(where you get all the support needed to take the career to the next level</em>), as well as free listing in the <strong>Global Talent Directory</strong> <em>(to gain exposure and receive new opportunities)</em>. In host contests, the host can nominate talents for the <em>CTM</em> program even if they don't win.",
    learnHowContesteeWorksDesc3: 'See this table for a clearer comparison:',
    becomeAHost: 'Become a Host',
    becomeAHostDescription:
      'Host your own contests and help share your fame with talented fans! If you’re an influencer, celebrity, or company then you can host your own contest on Contestee, with very little work involved. Contestee is designed to help you easily run your own contests while increasing engagement with talented fans.',
    the_100KGrandPrize: 'The $100K Grand Prize',
    the_100KGrandPrizeDesc1:
      'On top of contest prizes, Contestee features a <strong>$100,000 grand prize</strong> for becoming a Megastar Talent. As a Megastar, a talent also becomes eligible for the Contestee Talent Management program, where they receive the support they need to make their talent a career. To become a Megastar Talent, one has to earn 100 million votes.',
    the_100KGrandPrizeDesc2:
      'Voting Power and Voting Tickets mean that it’s easier than it seems to become a Megastar. Still, 100M votes can feel like a lot, and can take a long time for anyone starting out without already being well-known, which is why there’s a second path:',
    the_100KGrandPrizeDesc3:
      'In a <strong>Host Contest</strong>, the Host can use a <strong>Star Buzzer</strong> to instantly give the contest winner either 10M or 50M votes, making them a Star or a Superstar Talent! With that boost to becoming a Megastar, the winner can ride their momentum to earn the rest of the votes they need to become a Megastar and win!',
    theContesteeTalentManagementProgram:
      'The Contestee Talent Management Program',
    theContesteeTalentManagementProgramDesc1:
      'The <strong>Contestee Talent Management</strong> (CTM) program exists to provide the most eligible talents with the support they need to take their talents to the next level. The main goal of the CTM program is to help Contestee’s become tomorrow’s stars.',
    theContesteeTalentManagementProgramDesc2:
      'To qualify, talented contestants have to be nominated by a contest host. It doesn’t matter if a talent wins the contest, the host can nominate anyone who they think can become a star. Nominees then get reviewed by the Professional Review Board, who determine whether or not the nominee is eligible for the program. Eligible talents can then sign a contract with Contestee to gain access to the support they need to further their careers to the next level.',
    certificatesDescription:
      'Contestee is centered around helping talented people to grow and promote their talents. Whenever a contestant reaches a new Popularity Level, they earn an official Contestee Certificate. Certificates detail the talent’s accomplishments within Contestee, and can be used by the talents to share and promote their accomplishments via professional resumé, social media, or anywhere else.',
    /**
     * Landing Page: Main Contents - Why Choose Contestee?
     */
    whyChooseContestee: 'So Why Contestee?',
    whyChooseContesteeDescription1:
      'There are so many talented people in the world struggling for their chance to shine. But trying to gain recognition and build your fan-base through regular social media typically takes years of hard (and often fruitless) work. New talents struggle to be seen in such a vast sea of other social media stars, influencers, and celebrities. Understandably, a lot of people give up. This is where Contestee comes in to help.',
    whyChooseContesteeDescription2:
      'On a global stage with guaranteed exposure, every talent suddenly has new opportunities.',
    ourMission: 'Our Mission',
    ourMissionDescription: 'Global Talent Discovery through online competition',
    ourVision: 'Our Vision',
    ourVisionDescription: 'To become the world’s first Global Talent Directory',
    ourGoal: 'Our Goal',
    ourGoalDesc:
      'The goal of Contestee is sharing prosperity by providing value for all users through a fun, engaging, and rewarding platform:',
    ourGoalDesc1:
      '<strong>Voters:</strong> Providing a place where anyone can watch a variety of different videos, organized for total user control. Alongside this, to make sure everyone feels they are having a real impact when they vote.',
    ourGoalDesc2:
      '<strong>Contestants:</strong> Creating a Global Stage for talented people around the world to quickly and easily gain exposure and popularity',
    ourGoalDesc3:
      '<strong>Hosts:</strong> Providing a platform for celebrities and influencers to provide new opportunities and connect with talented fans and followers by hosting contests.',
    becomeAnAmbassador: 'Become an Ambassador',
    becomeAnAmbassadorDesc1:
      'Contestee is actively working with “ambassadors” to help us bring more talented, influential people to become Contestee hosts and contestants. An ambassador has to already have the network or connections to introduce Contestee to famous influencers or celebrities who may become Contestee Hosts. If you are interested in becoming an ambassador, feel free to ',
    becomeAnAmbassadorDesc2: 'Meet a few Contestee Ambassadors below.',
    becomeAnAmbassadorDesc3: 'contact us',
    appScreens: 'App Screens',
    faqS: "FAQ's",
    whatAreTheTwoTypesOfContests: 'What are the two types of contests?',
    whatAreTheTwoTypesOfContestsDesc1:
      'Although both types of contests take you to the same place, they offer two different paths:',
    whatAreTheTwoTypesOfContestsDesc2:
      '<strong>Popularity Contests</strong> are inclusive and casual,are like social media in that they’re open for everyone to share their talents. Earn votes to increase your popularity level and show off your social status.',
    whatAreTheTwoTypesOfContestsDesc3:
      '<strong>Host Contests</strong> are for people who want a fast-track to becoming famous. They are more like traditional contests, with multiple rounds for voters from around the world to watch and vote for the best. Each contest is hosted by a talented professional, such as a celebrity or influencer.',
    whatIsTheMegastarPrize: 'What is the Megastar Prize?',
    whatIsTheMegastarPrizeDesc1:
      'The Megastar Prize is a $100,000 prize anyone can win by earning 100 million votes in Contestee. This is not as hard as it sounds, because',
    whatIsTheMegastarPrizeDesc2: 'Voters can vote multiple times a day for you',
    whatIsTheMegastarPrizeDesc3: 'Voters can have higher Voting Power',
    whatIsTheMegastarPrizeDesc4:
      'Votes you earn in any contest count towards this achievement',
    whatIsTheMegastarPrizeDesc5:
      'Winning a Host Contest will instantly earn you <u>10M </u> or <u>50M</u> tickets!',
    whatIsContesteeTalentManagementCtm:
      'What is Contestee Talent Management (CTM)?',
    whatIsContesteeTalentManagementCtmDesc1:
      'Contestee Talent Management is a program designed to help exceptionally talented contestants take their careers to the next level. You can either be nominated by a host for your chance to become eligible, or become a Megastar to instantly qualify.',
    whatIsContesteeTalentManagementCtmDesc2:
      'Once a talented person qualifies, they can sign a contract with Contestee to gain access to everything they need to nurture and refine their talents, as well as professional opportunities from around the world.',
    whatKindsOfTalentsBelongOnContestee:
      'What kinds of talents belong on Contestee?',
    whatKindsOfTalentsBelongOnContesteeDesc:
      'Contestee is for every kind of talent, skill, and artwork. There are dozens of different categories and at least one contest for each. These include singing, dance, music, fitness, art, and more. There are always new kinds of contests being added as the demand increases.',
    willThereBeNewContestsAddedInTheFuture:
      'Will there be new contests added in the future?',
    willThereBeNewContestsAddedInTheFutureDesc1:
      'New Popularity Contests are constantly being automatically generated and added based on users’ posts.',
    willThereBeNewContestsAddedInTheFutureDesc2:
      'New Host Contests are always being added from new and old contest hosts.',
    whatMakesContesteeDifferentFromRunningAContestOnSocialMedia:
      'What makes Contestee different from running a contest on social media?',
    whatMakesContesteeDifferentFromRunningAContestOnSocialMediaDesc:
      'Contestee provides hosts with the specific tools they need to manage contests. Some of these components include a global stage, a voting ticket system, advertisement infrastructure, judges,real-life prizes, and much more.',
    howIsTheWinnerDecidedForEachContest: 'How do you win a contest?',
    howIsTheWinnerDecidedForEachContestDesc1:
      "For <strong>Host Contests</strong>, each round a certain number of contestants who earn the least votes are eliminated. In the final round, the contestant who earns the most votes wins the contest. But that isn't the only way to <a href='/en/prizes'>win the contest</a>...",
    howIsTheWinnerDecidedForEachContestDesc2:
      'Because <strong>Popularity Contests</strong> are without competition, there is no final winner. However, all the votes you earn count towards becoming a Megastar to win the $100,000 grand prize.',
    whatArePopularityLevels: 'What are Popularity Levels?',
    whatArePopularityLevelsDesc:
      'Your Popularity Level is a way to measure how many votes you’ve earned in Contestee. Each time you earn enough votes for the next level, you also earn an official Contestee Certificate and to show off your new status. Reach higher levels to win prizes, and reach Megastar level to win the',
    whatIsContesteeFaq: 'What is Contestee?',
    whatIsContesteeFaqDesc:
      'Contestee is the world’s first Global Talent Discovery Platform. Like a cross between TikTok and America’s Got Talent, anyone can download Contestee on their phone and upload their talent videos to dozens of different online contests.',
    whatIsTheGoalOfContestee: 'What’s the goal of Contestee?',
    whatIsTheGoalOfContesteeDesc:
      'Contestee has 3 main goals for 3 different types of people:',
    whatIsTheGoalOfContesteeGoal1:
      '<strong>Voters</strong>– To make a place where people can watch & vote for a variety of different videos organized for their interests, where they’ll like they’re having a <em>real</em> impact.',
    whatIsTheGoalOfContesteeGoal2:
      '<strong>Contestants</strong> – To create a Global Stage for talented people around the world to quickly and easily gain exposure.',
    whatIsTheGoalOfContesteeGoal3:
      '<strong>Hosts</strong>– To provide a platform for celebrities and influencers to earn revenue more fairly while they create new opportunities and connect with talented fans.',
    whoCanUseContestee: 'Who can use Contestee?',
    whoCanUseContesteeDesc:
      'Contestee is free on the App Store or Google Play. Anyone with a phone or WhatsApp number can create an account and then vote or join a contest.',
    whatKindsOfPeopleIsContesteeFor: 'Who is Contestee for?',
    whatKindsOfPeopleIsContesteeDesc1:
      'Contestee is made for three kinds of people:',
    whatKindsOfPeopleIsContesteeDesc2:
      '<strong>Contestants</strong> -Talented people of all kinds who want to gain fame and exposure.',
    whatKindsOfPeopleIsContesteeDesc3:
      '<strong>Hosts</strong> - Professional talents & celebrities who want to host their own contests.',
    whatKindsOfPeopleIsContesteeDesc4:
      '<strong>Voters</strong> - Anyone who loves contests and entertainment. ',
    howDoYouWinTheMegastarPrize: 'How do you win the $100,000 Megastar Prize?',
    howDoYouWinTheMegastarPrizeDesc:
      ' You can win the Megastar Prize by reaching <em>Megastar</em> Popularity Level through earning 100 million votes, which is easier than it seems. Votes are earned through participating in contests',
    whatTypesOfContestsAreThere: 'What types of Contests are there?',
    whatTypesOfContestsAreThereDesc:
      'There are two types of contests in Contestee: Popularity Contests & Host Contests. Both contests are a path towards winning the $100,000 Megastar Prize.',
    whatsTheDifferenceBetweenPopularityContestsHostContests:
      'What’s the difference between Popularity Contests & Host Contests?',
    whatsTheDifferenceBetweenPopularityContestsHostContestsDesc1:
      '<strong>Popularity Contests</strong> are more like social media. There is no competition, and no pressure or requirements, anyone can upload videos to earn votes from users worldwide. Each vote counts directly towards reaching new Popularity Levels and winning the Megastar Prize.',
    whatsTheDifferenceBetweenPopularityContestsHostContestsDesc2:
      "<strong>Host Contests</strong> are more like talent competitions you see on TV. These contests are hosted by popular celebrities and influencers. In Host Contests, you first have to submit an audition video and get accepted by the host. Afterwards, you need to earn enough votes through your talent videos each round, or get eliminated. Earn the most votes in the final round to win the contest and earn a Star Buzzer. Even if you don't win, if the host thinks you have what it takes they can nominate you for CTM.",
    whatIsTheStarBuzzer: 'What is the Star Buzzer?',
    whatIsTheStarBuzzerDesc:
      "When someone wins a host contest, the host can decide to use the Star Buzzer. Doing so will give the winner either <strong>10 million</strong> or <strong>50 million</strong> votes. From there, the winner has to earn the remaining 50 million votes in Popularity Contests to prove they're a popular talent.",
    howDoesTheAuditionProcessWork: 'How does the audition process work?',
    howDoesTheAuditionProcessWorkDesc:
      'Before a Host Contest starts, you first have upload a video of your talent as an audition. The contest host reviews every audition video and decides whether to accept or reject them as a contestant. It is up to the host to decide how many contestants they want, so not everyone can be accepted.',
    howDoYouAvoidGettingEliminatedFromAContest:
      'How do you avoid getting eliminated from a host contest?',
    howDoYouAvoidGettingEliminatedFromAContestDesc:
      'There’s no way to guarantee you won’t be eliminated, the only way is to earn enough votes by trying your hardest each round.',
    howManyTimesCanIVotePerDay: 'How many times can I vote per day?',
    howManyTimesCanIVotePerDayDesc:
      'You can vote as many times as you have voting tickets. There is no limit to how many times you can vote, neither per video nor per day.',

    megastarPrize: 'Megastar Prize',
    popularityLevel: 'Popularity Level',
    numberOfVotesEarned: 'Number of Votes earned',
    bronze: 'Bronze',
    silver: 'Silver',
    gold: 'Gold',
    platinum: 'Platinum',
    star: 'Star',
    superstar: 'Superstar',
    megastar: 'Megastar',
    whatAreContesteeCertificates: 'What are Contestee Certificates?',
    whatAreContesteeCertificatesDesc:
      'Whenever a talent reaches a new Popularity Level, they earn an official Contestee Certificate. Certificates outline their accomplishment within Contestee, and can be used by talents to share and promote their accomplishments via professional resumé, social media, or anywhere else.',
    canISeePeopleSOldContestsAndCertificates:
      'Can I see people’s old contests and certificates?',
    canISeePeopleSOldContestsAndCertificatesDesc:
      'Yes, all winners in Contestee are organized & categorized in a central directory that anyone can access. You can see a contestant’s history from their profile.',
    howDoIBecomeAVoter: 'How do I become a voter?',
    howDoIBecomeAVoterDesc:
      'Everyone can be a voter. As soon as you make a Contestee account, you can start voting for your favorite talents.',
    howDoIVote: 'How do I vote?',
    howDoIVoteDesc:
      'You can vote for posts that you like by pressing the Vote button at the bottom of a post. You can vote as many times as you like, but each vote costs 1 voting ticket. This applies for both Popularity Contests and Host Contests.',
    whatAreVotingTickets: 'What are voting tickets?',
    whatAreVotingTicketsDesc1:
      'To vote for someone, you have to spend a voting ticket. You start with 10 voting tickets, which refresh every day. This means if you don’t spend your voting tickets, you won’t get to spend them the next day.',
    whatAreVotingTicketsDesc2:
      'If you want more voting tickets, you can earn them by watching ads, inviting friends, or just browsing contests.',
    whatIsVotingPower: 'What is Voting Power?',
    whatIsVotingPowerDesc1:
      'Voting Power is how many votes each of your tickets are worth. To reach a new level for Voting Power, you have to cast a certain number of votes.',
    whatIsVotingPowerDesc2:
      'Reaching a new level not only increases your impact on choosing the contest winner, but can also earn you special rewards from sponsors!',

    voterLevel: 'Voter Level',
    voteRequirement: 'Vote Requirement',
    votingPower: 'Voting Power',
    regular: 'Regular',
    howCanIFilterOrFindCertainContests:
      'How can I filter or find certain contests?',
    howCanIFilterOrFindCertainContestsDesc:
      'If you don’t want to see certain contests or videos, you can pick and choose what categories you’re interested in using the contest filter.',
    canISearchForSpecificContestsOrPeople:
      'Can I search for specific contests or people?',
    canISearchForSpecificContestsOrPeopleDesc:
      'Yes, from the home page, you can use the search bar to find specific contestants or hosts, as well as videos with certain tags or languages.',
    whatIsAHost: 'What is a Host?',
    whatIsAHostDesc:
      'A host is a celebrity, artist, influencer, or company who runs their own contests on the Contestee.',
    whatIsTheDifferenceBetweenPopularityContestsAndHostContests:
      'What is the difference between Popularity Contests and Host Contests?',
    whatIsTheDifferenceBetweenPopularityContestsAndHostContestsDesc1:
      '<strong>Popularity Contests:</strong> Like in social media, anyone can upload photos or videos showing off their talent in order to earn votes from people around the world.',
    whatIsTheDifferenceBetweenPopularityContestsAndHostContestsDesc2:
      '<strong>Host Contests: </strong> Like in traditional contests, people who want to get more exposure, faster can audition to be a part of a contest. The host gets to set the rules and decide who is accepted. Contestants then upload a video each round in order to earn the most votes or else get eliminated. The winner of the final round gets a Star Buzzer from the host as well as any sponsored prize(s).',
    whatDoesAHostDo: 'What does a Host do?',
    whatDoesAHostDoDesc1:
      'A host runs one or more contests on the Contestee app. However, they do not have to run everything themselves. Most aspects of running a contest are automated, most of the required work can be delegated to an admin. The host decides the criteria, rules, dates, and contestants for a contest. The host has to approve which advertisers they want on their page, and can find sponsors for the contest. The host also has to promote their own contest, as well as occasionally engage and interact with their followers in Contestee’s new kind of social media environment. For the final round, the host gets to decide which Star Buzzer the winner deserves, and who they want to nominate for <u>CTM</u>.',
    whatDoesAHostDoDesc2:
      'Additional questions can be answered in this FAQ, or by a Contestee representative, who supports and guides hosts & admins on how to manage their new page.',
    whatIsAnAdmin: 'What is an Admin?',
    whatIsAnAdminDesc:
      "An admin is someone who manages the technical aspects of a host contest. Most all the work involved with being a Contestee host can be delegated to an admin. There's no limit to how many admins you can have, but admins have to be someone the host trusts to have direct access to their page. The host can hire their own admins, or entrust the responsibility to a close friend or family member.",
    whoCanBecomeAHost: 'Who can become a Host?',
    whoCanBecomeAHostDesc:
      'Only popular figures such as celebrities, artists, influencers, or companies can become Hosts. Contestee individually reviews and corresponds with each applicant to determine their eligibility.',
    howCanIBecomeAHost: 'How can I become a Host?',
    howCanIBecomeAHostDesc1:
      'If you think you qualify, you can contact us through the form at the bottom of this page or through ',
    howCanIBecomeAHostDesc2: 'this link',
    howCanIBecomeAHostDesc3:
      '. Once we review your eligibility and verify your identity, we will contact you directly for more information.',
    connectWithUs: 'Connect With Us',
    notifyMeOnceContesteeLaunches: 'Notify me once Contestee launches',
    comingSoon: 'Coming soon!',
    name: 'Name',
    email: 'Email',
    askUsAnything: 'Ask Us Anything',
    submit: 'Submit',
    alex: 'Alex',
    marie: 'Marie',
    adriana: 'Adriana',
    amelia: 'Amelia',
    linda: 'Linda',
    will: 'Will',
    unitedStates: 'United States',
    germany: 'Germany',
    france: 'France',
    australia: 'Australia',
    canada: 'Canada',
    leilaForouhar: 'Leila Forouhar',
    shohrehAghdashloo: 'Shohreh Aghdashloo',
    schubertAvakian: 'Schubert Avakian',
    paksimaZakipour: 'Paksima Zakipour',
    raminZamani: 'Ramin Zamani',
    hamidSaeidi: 'Hamid Saeidi',
    summerAlami: 'Summer Alami',
    monaNikoo: 'Mona Nikoo',
    samiraNosrati: 'Samira Nosrati',
    babakRahimi: 'Babak Rahimi',
    yasamanDance: 'Yasaman Dance',
    pedramCouture: 'Pedram Couture',
    eritMajdipour: 'Erit Majdipour',

    verifyUserInputInformation:
      'Becuase any Talent or Active Voter can win prizes or rewards in contestee, your profile information must be both True & Accurate in order to receive any prizes or rewards.',
    mobileNumber: 'Mobile Number',
    nowAvailableOn: 'Now available on',
    getTheLinkTo: 'Get the link to',
    installApp: 'install app',
    verifyMobileNumber: 'Verify Mobile Number',
    verificationCode: 'Verification Code',
    weHaveSentVerificationCodeTo: 'We have sent verification code to',
    resend: 'Resend',
    regularUser: 'User Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    select: 'Select',
    country: 'Country',
    emailAddress: 'Email Address',
    byCreatingYourAccountYouAgreeTo: 'By creating your account you agree to',
    termsCondition: 'Terms & Condition',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    done: 'Done',
    copyright: 'Copyright',
    allRightsReserved: 'All Rights Reserved',
    poweredBy: 'Powered By',

    /***************************************************
     * Prizes Page:
     ***************************************************/
    contestWinner: 'Contest Winner',
    contestWinnerDescription:
      'The talent who earns the most votes in the final round is the winner of the contest. The winner may get a prize from the host or sponsors.',
    starBuzzerWinner: 'Star Buzzer Winner',
    starBuzzerWinnerDescription:
      'The host and judges can use the <strong>Star Buzzer</strong> to instantly give any finalists <em>(not just the winner)</em> either 10M or 50M votes. This automatically makes them a Star or Superstar Talent, and boosts them towards winning the $100K Megastar Prize.',
    ctmNominationWinner: 'CTM Nomination Winner',
    ctmNominationWinnerDescription:
      'The host & judges have the power to nominate any finalists <em>(not just the winner)</em> for the Contestee Talent Management (CTM) Program, where eligible contestants get all the support they need to take their careers to the next level.',
    faqContesteeTitle: 'Contestee',
    faqContestantsTitle: 'Contestants',
    faqVoterTitle: 'Voters',
    faqHostTitle: 'Hosts',

    /***************************************************
     * Host Requirements Page:
     ***************************************************/
    requirementsForNewHosts: 'Requirements for New Hosts',
    requirementsForNewHostsDesc1:
      'The purpose of this page is to detail everything you need to do in order to be a Contestee Host. (Currently, becoming a host is reserved for eligible influencers, celebrities, and companies)',
    requirementsForNewHostsDesc2:
      'If you have already been approved as a Host, coordinate with your Contestee representative to make it official by completing these steps:',
    signTheHostAgreement: 'Sign the <em>Host Agreement</em>.',
    sendFourDocuments: 'Send four (4) or more hi-res, high-quality pictures.',
    chooseTheNameOfYourContestAndConsiderWhoYouWantToInviteAsAJudge:
      'Choose the name of your contest and some people you may want to invite as judges.',
    forAnyCustomProductsYouWantToSellInTheContesteeMall:
      'Provide the following information:',
    contesteeMallInfo1:
      'For any custom products you want to sell in the Contestee Mall:',
    contesteeMallInfo2: 'For any prizes you want to include in the contest:',
    productName: 'Product name',
    productPrice: 'Product price',
    productPhoto: 'Product photo',
    prizeName: 'Prize Name',
    prizeInfo: 'Prize info',
    productDescription: 'Product description',
    forAnyPrizesYouWantToIncludeInTheContest:
      'For any prizes you want to include in the contest:',
    sendTheCoverPhotoForYourContest:
      'Send a high-resolution cover-photo for your contest',
    highResolution: 'High-resolution',
    mainSubjectOfTheCoverPhotoShouldBeOnTheRighthandSideForExample:
      'Main subject of the cover photo should be on the right-hand side, for example:',
    highlyRecommendedInformation:
      '<em>(For the following steps, it is highly recommended to use the</em> <strong>Host Guide</strong> <em>if you do not know how to complete a step or just feel a little lost)</em>',
    addInfoToHostProfileSeeHostGuide:
      'Add info to Host Profile <em>(See Host Guide)</em>',
    profilePic: 'Profile Pic',
    bio: 'Bio',
    accomplishmentsAwards: 'Accomplishments & Awards',
    links: 'Links',
    createContestAndAddContestInfoSeeHostGuide:
      'Create contest and add contest info <em>(See Host Guide)</em>.',
    promoteYourContestOnYourSocialMediaAndOtherPlatforms:
      'Promote your contest on your social media and other platforms',
    hostGuideWillHelpYou:
      '<em>For everything after these requirements, the Contestee</em> <strong>Host Guide</strong> <em>will help you</em>.',
    or: 'or',

    /***************************************************
     * Validation Messages
     ***************************************************/
    pleaseInputYourName: 'Please input your name',
    pleaseEnterFirstName: 'Please enter first name',
    pleaseEnterLastName: 'Please enter last name',
    pleaseSelectCity: 'Please select city',
    pleaseSelectState: 'Please select state',
    invalidEmailAddress: 'Invalid Email Address',
    pleaseInputYourEMail: 'Please input your E-mail!',
    pleaseInputYourQuestion: 'Please enter your question',

    /***************************************************
     * Success / Error / Info Messages
     ***************************************************/
    thankYouForContactingUs: 'Thank you for contacting us!!',
    toManyAttemptsForResendOtp: 'To many attempts for resend OTP',
    otpSentSuccessfully: 'OTP sent successfully',
    noMatchFound: 'No match found!',
    pleaseWait: 'Please Wait...',
    countryChangeInfo:
      'In order to change country you must have to change and verify phone number',
    termAcceptInfo: 'Please agree to terms & condition and privacy policy',

    /***************************************************
     * Voter Guide
     ***************************************************/
    voterGuideTitle: 'Voter Guide',
    voterGuideDesc:
      'This guide will help guide you through creating your account and casting your first vote in Contestee.',
    voterGuideContents: 'Contents',
    voterDownloadTheContesteeApp: 'Download the Contestee App',
    createYourAccount: 'Create your account',
    createYourAccountDesc:
      'Once you’ve downloaded the app, it is time to create your account. Your account is directly connected to your phone number. No one can access your account without your permission.',
    howToVote: 'How to Vote',
    howToVoteDesc:
      'Now that you’ve created your account, you can start voting! You can vote for anyone in the app by tapping the “Vote” button at the bottom of someone’s post. Adjust the slider to decide how many votes you want to give them.',
    otherFeatures: 'Other Features',
    voterDownloadTheContesteeAppDesc:
      'If you haven’t already, you will first have to download the Contestee app for free through either the',
    appStore: 'App Store',
    googlePlay: 'Google Play',
    openTheContesteeApp: 'Open the Contestee App',
    enterYourPhoneNumber: 'Enter your phone number',
    selectYourCountryCodeByTappingTheFlagIcon:
      'Select your country code by tapping the flag icon',
    tapTheArrowEnterThe_5DigitVerificationCodeSentToYourPhone:
      'Tap the arrow & enter the 5-digit verification code sent to your phone',
    chooseAProfilePicture: 'Choose a profile picture',
    enterYourName: 'Enter your name',
    enterYourCity: 'Enter your city',
    enterYourEmail: 'Enter your email',
    tapSubmit: 'Tap “Submit”',
    toEditThisInformationLater: 'To edit this information later:',
    openYourProfile: 'Open your profile',
    tapOnYourProfilePicture: 'Tap on your profile picture',
    editAnySectionByTappingIt: 'Edit any section by tapping it',
    votingTicketsTitle: 'Voting Tickets',
    votingTicketsDesc:
      'Each time you vote, you have to spend voting tickets. Every day, you get up to 10 of your tickets refilled. If you want to earn more tickets, you can earn them by:',
    browsingContests:
      '<strong>Browsing contests</strong> – 1 ticket for every 10 posts you watch',
    watchingAds:
      '<strong>Watching ads</strong> – 1 ticket whenever you watch a sponsored ad',
    invitingFriends:
      '<strong>Inviting Friends</strong> – 10 tickets whenever a friend signs up with your invite link',
    votingPowerTitle: 'Voting Power',
    votingPowerDesc:
      'If you are active with voting, you will reach a higher level of Voting Power. Higher Voting Power means each of your tickets are worth more votes.',
    theDifferentLevelsForVotingPowerAre:
      'The different levels for voting power are:',
    level: 'Level',
    requirement: 'Requirement',
    regularTitle: 'Regular',
    none: 'None',
    vote_1_000Times: 'Vote <strong>1,000</strong> times',
    vote_1_0000Times: 'Vote <strong>10,000</strong> times',
    vote_100_000Times: 'Vote <strong>100,000</strong> times',
    vote_1_000_000Times: 'Vote <strong>1,000,000</strong> times',
    votintPowerTableDesc:
      'Each time you reach a new level not only will your power to influence the contests increase, but you also can earn rewards from sponsors!',
    howToShareAPost: 'How to share a post',
    youCanShareAnyPostFromHostOrPopularityContestsByFollowingTheseSteps:
      'You can share any post from Host or Popularity contests by following these steps:',
    openThePostYouWantToShare: 'Open the post you want to share',
    tapThe_3DotsOnTheRightSideOfThePost:
      'Tap the 3 dots on the right side of the post',
    selectShare: 'Select “Share”',
    chooseHowYouWantToShareThePost: 'Choose how you want to share the post',
    howToBookmarkAPost: 'How to Bookmark a post',
    bookmarkSteps:
      'You can save posts in Contestee using the bookmark feature. Just following these steps:',
    openThePostYouWantToSave: 'Open the post you want to save',
    selectBookmarkPost: 'Select Bookmark Post',
    howToViewYourBookmarkedPosts: 'How to view your Bookmarked posts',
    viewBookmarkedPostsTitle:
      'Follow these steps to view your bookmarked posts:',
    openTheProfilesPageByTappingTheBottomRightIcon:
      'Open the Profiles page by tapping the bottom-right icon',
    openYourVoterProfile: 'Open your Voter Profile',
    selectSavedPosts: 'Select “Saved Posts”',
    giveFeedbackToDevelopers: 'Give feedback to developers',
    giveFeedbackToDevelopersDesc:
      'Follow these steps to report bugs or give feedback directly to us at Contestee.',
    scrollToTheBottomOfTheHomePage: 'Scroll to the bottom of the home page',
    tapMore: 'Tap “More”',
    selectFeedback: 'Select “Feedback”',
    sendUpTo_3ScreenshotsAndAShortMessageToOurDevelopers:
      'Send up to 3 screenshots and a short message to our developers',
    viewAllContestPosts: 'View all Contest Posts',
    viewAllContestPostsDesc:
      'You can see all the posts in a contest directly from a post in that contest. Just follow these steps:',
    selectShowAllPosts: 'Select “Show all Posts”',
    contestStage: 'Contest Stage',
    contestStageDesc:
      'In a host contest, you can find a variety of different icons with different functions, as follow:',
    announcements: 'Announcements',
    opensTheContestSAnnouncements: 'Opens the contest’s Announcements.',
    judgeFeedback: 'Judge Feedback',
    judgeFeedbackDesc:
      'Shows performance feedback from the host & judges, if the contestant has any.',
    contestResults: 'Contest Results',
    contestResultsDesc:
      'Shows the results & statistics of each round so far. Includes all the live contestants, eliminated contestants, votes earned, and start dates for each round.',
    commentsVoiceClub: 'Comments & Voice Club',
    commentsVoiceClubDesc:
      'Shows comment section for you to read other people’s comments or write your own. You can also access the unreleased Voice Club feature from here.',
    moreOptions: 'More Options',
    showAllPosts:
      '<strong>Show All Posts</strong> – Shows all posts for the current round of the contest.',
    bookmarkSavePostListDesc:
      '<strong>Bookmark</strong> – Save this post to your bookmarks in the app. To see our bookmarked posts:',
    shareALinkToThisPost: '<strong>Share</strong> – Share a link to this post',
    aboutContestListDesc:
      '<strong>About Contest</strong> – Takes you to the contest info page',

    /***************************************************
     * Contestant Guide
     ***************************************************/
    contestantGuideDesc: 'A step-by-step guide to joining a host’s contest.',
    searchForHost: 'Search for Host',
    auditionForContest: 'Audition for Contest',
    onceYouVeFinishedDownloadingOpenTheApp:
      '. Once you’ve finished downloading, open the app.',
    auditionForContestDesc:
      'To join a contest, you first have to upload an audition video, which will be reviewed and then either accepted or disqualified by the contest host. You can only upload 1 audition for each contest, if it’s accepted it will be used for the 1st round. Your video should be appropriate for the contest and under 90 seconds long.',
    selectTheContestAndTapBecomeAContestant:
      'Select the Contest and tap “Become a Contestant”',
    tapTheCheckboxAndThenTapNext: 'Tap the checkbox and then tap “Next”',
    reviewTheVideoRequirementsAndTapOk:
      'Review the video requirements and tap “Ok”',
    uploadYourVideo: 'Upload your video',
    addHashtags: 'Add hashtags',
    searchForHostDesc:
      'Now that your account is set up, you can find and audition for the contest. Note that you cannot join host contests that have already started, only contests that are still in the audition phase.',
    searchForHostInfo1: 'Tap the button in the bottom-center.',
    searchForHostInfo2: 'Tap “Hosts"',
    searchForHostInfo3:
      'Enter the host’s name for the contest you want to join.',
    searchForHostInfo4: 'Tap the host to view their profile',
    searchForHostInfo5: 'Select the host’s contest that you want to join',
    searchForHostInfo6:
      'Tap on your picture to upload your own audition video.',

    /***************************************************
     * Host Guide
     ***************************************************/
    hostGuide: 'Host Guide',
    hostGuideDesc:
      'The following document will guide you through creating and managing your profile as a Contestee host.',
    manageAdmins: 'Manage Admins',
    addAnAdmin: 'Add an Admin',
    removeAnAdmin: 'Remove an Admin',
    createYourContest: 'Create your Contest',
    hostPage: 'Host Page',
    winners: 'Winners',
    turnout: 'Turnout',
    followers: 'Followers',
    posts: 'Posts',
    hostActions: 'Host Actions',
    startALivestream: 'Start a Livestream',
    livestreamOptions: 'Livestream Options',
    savedLivestreams: 'Saved Livestreams',
    accountingReport: 'Accounting Report',
    promoteContest: 'Promote Contest',
    hostActionsInContestStage: 'Host Actions in Contest Stage',
    sendFeedback: 'Send Feedback',
    starBuzzer: 'Star Buzzer',
    nominate: 'Nominate',
    contestManagement: 'Contest Management',
    auditions: 'Auditions',
    startTheContest: 'Start the Contest',
    startANewRound: 'Start a New Round',
    endTheContest: 'End the Contest',
    merchandiseStore: 'Merchandise Store',
    enterYourBio: 'Enter your Bio',
    enterYourAccomplishments: 'Enter your Accomplishments',
    enterYourAwards: 'Enter your Awards',
    enterYourLinks: 'Enter your links',
    tap: 'Tap',
    buttonToAddALink: 'button to add a link',
    enterLinkNameExFacebookInstagramMyWebsiteEtc:
      'Enter Link Name (ex: Facebook, Instagram, My Website, etc.)',
    enterUrl: 'Enter URL',
    tapSave: 'Tap “Save”',
    addStore: 'Add Store',
    asAHostYouGetYourOwnOnlineStoreAndMerchandiseToSellInThe:
      'As a host, you get your own online store and merchandise to sell in the',
    contesteeMall: 'Contestee mall',
    evenIfYouHaveNoMerchandiseYourselfContesteeWillCreateDesignNewCustomMerchandiseForYou:
      '. Even if you have no merchandise yourself, Contestee will create & design new, custom merchandise for you.',
    ifYouHaveYourOwnProductsFirstSendTheFollowingToYourAmbassador:
      'If you have your own products, first send the following to your ambassador:',
    productPicture: 'Product Picture',
    productDescriptionOptional: 'Product Description <em>(optional)</em>',
    productDescriptionOptionalDesc:
      'Before your store can be finished, you must send a <u>high-definition</u> photo of yourself to be used for your cover page & custom-designed products.',
    allYouNeedToDoNowIsLinkYourStorePageToYourProfile:
      'All you need to do now is link your store page to your profile:',
    goToYourProfile: 'Go to your profile',
    tapYourProfilePicture: 'Tap your profile picture',
    scrollToTheBottom: 'Scroll to the bottom',
    tapThePlusButtonNextToStores: 'Tap the plus button next to “Stores”',
    uploadYourStoreSCoverPageProvidedByContestee:
      'Upload your store’s cover page (provided by Contestee)',
    typeMyStoreForYourStoreName: 'Type “My Store” for your store name',
    copyAndPasteYourStoreLinkExample:
      'Copy and paste your store link (example:',
    storeYourName: '/store/your-name)',
    manageAdminsDesc:
      'An admin is someone who helps you manage all the technical aspects of your Host Profile. As a Host, there are only a few things that you personally need to do yourself. Everything else can be done by your admin(s). An admin should be someone you trust, who can take the time to learn the ins and outs of running contests and managing your profile. You can ask your ambassador to be your admin.',
    addAdminDesc:
      'For an admin to manage your page, all you need to do is give them access to your account.',
    haveYourAdminLogInUsingYourPhoneNumber:
      'Have your admin log in using your phone number',
    sendThemThe_5DigitNumberYouReceive:
      'Send them the 5-digit number you receive',
    onceTheyReLoggedInTheyLlBeAbleToHelpManageYourPage:
      'Once they’re logged in, they’ll be able to help manage your page.',
    removeAnAdminDesc:
      'To take away access from an admin, simply log out from all devices:',
    selectLogout: 'Select “Logout”',
    selectLogoutFromAllDevices: 'Select “Logout from all devices”',
    thisWillKickEveryoneOffOfYourAccountToReAccessItTheyWillRequireYouToSendThemACode:
      'This will kick everyone off of your account. To re-access it, they will require you to send them a code.',
    goToProfiles: 'Go to Profiles',
    selectYourHostProfile: 'Select your “Host Profile”',
    tapOnHostActions: 'Tap on “Host Actions”',
    selectWord: 'Select “',
    createANewContest: 'Create a New Contest',
    enterContestName: 'Enter Contest Name',
    addACoverPhoto: 'Add a Cover Photo',
    selectTheStartDate: 'Select the Start Date',
    thisIsTheDateThatAuditionsWillEndAndVotingWillBegin:
      'This is the date that auditions will end, and voting will begin.',
    peopleWillBeAbleToAuditionAsSoonAsYouFinishCreatingTheContest:
      'People will be able to audition as soon as you finish creating the contest.',
    addAboutContest: 'Add "About Contest”',
    copyAndPasteTheTextDescriptionThatWillBeProvidedForYou:
      'Copy and paste the text description that will be provided for you.',
    forEachOfTheFollowingSectionsTapThe:
      '(For each of the following sections, tap the',
    buttonToAddInformation: 'button to add information)',
    addInstructionVideosWhatToDoInEachRound:
      'Add Instruction videos (“What to do in each round”)',
    eachRoundForYourContestYouShouldUploadAVideoExplainingTheSpecificRules:
      'Each round for your contest you should upload a video explaining the specific rules.',
    forTheFirstVideoAuditionInstructionsMakeSureToCoverTheFollowing:
      'For the first video (audition instructions), make sure to cover the following:',
    whatTheContestIsAbout: 'What the contest is about.',
    theContestRules: 'The contest rules.',
    thatVideosNeedToBeUnder_90Seconds:
      'That videos need to be under 90 seconds.',
    afterTheAuditionRoundEachVideoOnlyNeedsToExplainTheRulesForTheNextRound:
      'After the audition round, each video only needs to explain the rules for the next round.',
    itIsAlsoEncouragedToCongratulateContestantsAndWishThemLuck:
      'It is also encouraged to congratulate contestants and wish them luck.',
    addPrizeS: 'Add Prize(s)',
    selectThisPrizeType: 'Select this “Prize Type"',
    service: '“Service”',
    enterThisSponsorName: 'Enter this “Sponsor Name”',
    contestee: '“Contestee”',
    enterThisServiceName: 'Enter this “Service Name”',
    starBuzzerWithQuotes: '“Star Buzzer”',
    enterThisPrizeUrl: 'Enter this prize URL',
    uploadPrizePhoto: 'Upload prize photo',
    tapAdd: 'Tap “Add”',
    setPerformanceEvaluationCriteria: 'Set “Performance Evaluation Criteria”',
    theEvaluationCriteriaRepresentHowTheContestantsShouldBeJudged:
      'The Evaluation Criteria represent how the contestants should be judged.',
    includeAtLeast_3_4DifferentCriteria:
      'Include at least 3-4 different criteria.',
    enterTheNamePercentageAndTapAdd:
      'Enter the name, percentage, and tap “add”',
    asAnExampleInADanceCompetitionTheCriteriaMightBe_40Technique_30Performance_20MusicalityTimingAnd_10Choreography:
      'As an example, in a dance competition the criteria might be: 40% technique, 30% performance, 20% Musicality & Timing, and 10% Choreography.',
    example: 'EXAMPLE:',
    inviteJudges: 'Invite Judges',
    youCanInviteYourTalentedFriendsOrPeersAsJudgesToHelpDecideTheWinnerAndGiveContestantsAdvice:
      ' - You can invite your talented friends or peers as judges to help decide the winner and give contestants advice.',
    enterTheirPhoneNumber: 'Enter their phone number',
    tapGetUserInfo: 'Tap “Get User Info”',
    confirmThePhoneNumberWasCorrectAndTapAdd:
      'Confirm the phone number was correct and tap “add”',
    addEnrollmentFees: 'Add Enrollment Fees',
    optionalTapTheSwitchToEnableFees:
      'Optional – tap the switch to enable fees.',
    processingFeeCostToAudition: '<em>Processing fee</em> – cost to audition',
    entryFeeCostToJoinIfAccepted:
      '<em>Entry Fee</em> – cost to join if accepted',
    addBillboardAdvertisementCost: 'Add Billboard advertisement cost',
    enterTheRateApprovedByContestee: 'Enter the rate approved by Contestee.',
    tapCreate: 'Tap “Create”',
    ifYouWantToEditInformationLater: 'If you want to edit information later:',
    goToHostProfile: 'Go to Host Profile',
    selectTheThreeDots: 'Select the three dots (',
    inTheTopRightCorner: ') in the top right corner',
    tapEditButton: 'Tap “Edit” button',
    makeChanges: 'Make changes',
    scrollDownTapSave: 'Scroll down & tap “Save”',
    everythingVisibleToYouOnYourHostPageOtherThanHostActionsIsVisibleToThePublic:
      'Everything visible to you on your host page, other than host actions, is visible to the public:',
    showsHowManyPeopleHaveWonInYourContestsTapToSeeDetails:
      'Shows how many people have won in your contests. Tap to see details.',
    showsHowManyTotalVotesPeopleHaveCastInAllYourContests:
      'Shows how many total votes people have cast in all your contests.',
    showsHowManyPeopleAreFollowingYouFollowersWillGetANotificationWheneverYouMakeAPostOrAnnouncement:
      'Shows how many people are following you. Followers will get a notification whenever you make a post or announcement.',
    toSeeYourPosts: 'To see your posts:',
    openHostProfile: 'Open host profile',
    tapPosts: 'Tap “Posts”',
    addAPost: 'Add a post',
    tapThePlusIconToEither: 'Tap the Plus icon to <em>either</em>:',
    takeAPhotoVideoOnYourCamera: 'Take a photo/video on your camera',
    selectUsePhoto: 'Select “Use Photo”',
    importAPhotoVideoFromYourPhotoGallery:
      'Import a photo/video from your photo gallery',
    selectChoose: 'Select “Choose”',
    waitForYourUploadToComplete: 'Wait for your upload to complete',
    deleteAPost: 'Delete a post',
    tapOnOneOfYourPosts: 'Tap on one of your posts',
    selectTheLargeDeleteButton: 'Select the large “DELETE” button',
    asAHostYouCanStartAPublicLivestreamWhereAnyoneCanJoinAndWatchYouTalkByYourselfOrWithGuestSAllOfYourFollowersWillGetANotificationWheneverYouGoLive:
      'As a host, you can start a public livestream where anyone can join and watch you talk by yourself or with guest(s). All of your followers will get a notification whenever you go live.',
    goToProfilesBottomRightIcon: 'Go to Profiles (Bottom-right icon)',
    selectLiveChat: 'Select “Live Chat”',
    tapStartNow: 'Tap “Start Now”',
    duringALivestreamThereAreAFewActionsYouCanDoThroughTheButtonsAtTheBottomOfYourScreenFromLeftToRightTheOptionsAre:
      'During a livestream, there are a few actions you can do through the buttons at the bottom of your screen. From left to right, the options are:',
    commentSendACommentForAllYourViewersToSee:
      '<strong>Comment</strong> – Send a comment for all your viewers to see.',
    questionsAnswerQuestionsFromYourViewers:
      '<strong>Questions</strong> – Answer questions from your viewers.',
    muteTapToMuteOrUnmuteYourMicrophone:
      '<strong>Mute</strong> – Tap to mute or unmute your microphone.',
    cameraSwitchBetweenYourFrontAndRearCameras:
      '<strong>Camera</strong> – Switch between your front and rear cameras.',
    addLive:
      '<strong>Add Live</strong> – Add another user to join you on video (this will split the screen with them). Either search their name and tap “Add”, or add them through requests.',
    emojiSendAnEmojiAcrossTheScreen:
      '<strong>Emoji</strong> – Send an emoji across the screen.',
    allOfYourLiveVideosAreAutomaticallySavedToYourHostPageForAnyoneToWatch:
      'All of your live videos are <em>automatically</em> saved to your host page, for anyone to watch.',
    toAccessYourLivestreamVideos: 'To access your livestream videos:',
    goToYourHostPage: 'Go to your host page',
    selectPosts: 'Select Posts',
    selectYourLiveVideosArchive: 'Select your “Live Videos Archive”',
    accountingReportsDesc:
      'Accounting reports are currently unavailable, and will be added in a future update.',
    promoteContestDesc:
      'It’s important to promote and share your contest before it begins. To get a link for your contest, or share it on another app, follow these steps:',
    goToYourHostProfile: 'Go to your “Host Profile”',
    tapOnTheContest: 'Tap on the Contest',
    selectShareContestLink: 'Select “Share Contest Link”',
    theContestStageIsWhereAllYourContestantSPostsCanBeSeenToQuicklyAccessYourContestStage:
      'The contest stage is where all your contestant’s posts can be seen. To quickly access your contest stage:',
    goToYourHostProfile1: 'Go to your Host Profile',
    openYourContest: 'Open your Contest',
    goToTheContestantsPage: 'Go to the Contestants page',
    selectViewContestStage: 'Select “View Contest Stage”',
    thereAreSeveralOptionsThatAnyoneCanSeeOnTheContestStageFromTopToBottomTheseOptionsAre:
      'There are several options that anyone can see on the contest stage. From top to bottom, these options are:',
    whenYouReLookingAtAnyPostInYourContestStageYouHaveSeveralUniqueHostActionsInAdditionalToThePreviousOnes:
      "When you're looking at any post in your contest stage, you have several unique host actions in additional to the previous ones.",
    voteAsAHost: 'Vote as a HOST',
    unlikeVotingNormallyVotingAsAHostIsPublicAndEachVoteIsWorth_100Points:
      'Unlike voting normally, voting as a host is public- and each vote is worth 100 points.',
    giveFeedback: 'Give Feedback',
    sendAFeedbackResponseToTheContestantWhereYouCanGiveThemPraiseCriticismAndAdviceOnHowToImprove:
      'Send a feedback response to the contestant, where you can give them praise, criticism, and advice on how to improve.',
    enabledAfterTheContestStarts: '(Enabled after the contest starts)',
    giveTheContestWinnerEither_10MillionOr_50MillionVotes:
      'Give the contest winner either 10 million or 50 million votes.',
    onlyUseableOnContestFinalistsAfterTheFinalRoundEnds:
      '(Only useable on contest finalists after the final round ends)',
    nominateDesc:
      'Nominate the contestant for the CTM (Contestee Talent Management) program. Nominees don’t automatically qualify until after review from the Professional Review Board.',
    nextRound: 'Next Round',
    endTheCurrentRoundOfTheContestToStartANewRoundInTheContest:
      'End the current round of the contest to start a new round in the contest.',
    liveChat: 'Live Chat',
    shareContestLink: 'Share Contest Link',
    shareALinkToThisContest: 'Share a link to this contest.',
    editContestInfo: 'Edit Contest Info',
    openTheContestInfoPanelToMakeChanges:
      'Open the contest info panel to make changes.',
    contestManagementDesc:
      'The most important aspect of being a host is knowing how to start, run, and end a contest.',
    auditionsDesc:
      'As soon as you submit your contest, it will be visible. People will be immediately able to start auditioning to become contestants. Until the contest begins, only you will be able to watch the audition videos.',
    selectTheContestViewTheAuditionsPage:
      'Select the contest view the Auditions page',
    tapAnAuditionVideoToWatchIt: 'Tap an audition video to watch it',
    acceptReject:
      'Decide whether to <strong>Accept</strong>, <strong>Reject</strong>, or <strong>Delete</strong> the audition',
    acceptReject1:
      '<strong>Accept</strong> – The talent will become a contestant. Their video becomes publicly visible.',
    acceptReject2:
      '<strong>Reject</strong> – The talent will not become a contestant. Their video becomes publicly visible.',
    acceptReject3:
      '<strong>Delete</strong> – The talent will not become a contestant. Their video will not be publicly visible.',
    important: 'Important!!!',
    makeSureToDeleteInappropriateVideosDoNotRejectThem:
      'Make sure to DELETE inappropriate videos, do NOT reject them.',
    onceTheStartDateHasArrivedAndYouHaveReviewedAllTheAuditionsTheContestCanBegin:
      'Once the start date has arrived and you have reviewed all the auditions, the contest can begin.',
    selectStartContest: 'Select “Start Contest”',
    onceYourContestHasBegunYouCanFollowTheseStepsToEndTheCurrentRoundAndStartRound_2:
      'Once your contest has begun, you can follow these steps to end the current round and start round #2.',
    goToTheContestStage: 'Go to the contest stage',
    tapHostActions: 'Tap “Host Actions”',
    selectNextRound: 'Select “Next Round”',
    startANewRoundDesc:
      'Select how many contestants you want to ELIMINATE from the next round (the remaining contestants will move onto the next round). Contestants are then automatically eliminated based on how many votes they have.',
    tapDoneToLockInTheRemainingContestants:
      'Tap “Done” to lock in the remaining contestants',
    startingTheFinalRoundForAContestIsTheSameAsStartingANewRoundTheKeyDifferenceComesOnceYouWantToEndTheContest:
      'Starting the final round for a contest is the same as starting a new round. The key difference comes once you want to end the contest:',
    whenYouWantToEndTheFinalRoundEliminateAllContestantsExceptForOne:
      'When you want to end the final round, eliminate ALL contestants except for one.',
    forExampleIfThereAre_10ContestantsLeftYouShouldEliminate_10_1_9Contestants:
      'For example, if there are 10 contestants left, you should eliminate 10-1=9 contestants',
    afterPressingDoneTheWinnerBecomesOfficialAndYouCanSelectWhetherToGiveThemA:
      'After pressing “Done”, the winner becomes official, and you can select whether to give them a',
    starOrSuperstarBuzzer: 'Star or Superstar buzzer.',
    starOrSuperstarBuzzerDesc:
      'Once the contest is over, it will be marked “Finished” on your page. Voters will still be able to open the contest, see results, and watch the contest videos.',
    evenIfYouHaveNoMerchandiseYourselfContesteeWillCreateDesignNewMerchandiseForYou:
      '. Even if you have no merchandise yourself, Contestee will create & design new merchandise for you.',
    ifYouHaveYourOwnProductsFirstSendTheFollowing:
      'If you have your own products, first send the following:',
    productInfo:
      'Before your store can be set up, first <u>send a high-definition photo</u> (for cover page & custom-designed products)',
  },
}

export default en
