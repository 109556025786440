import * as types from '../types'
const initialState = {
  lng: 'EN', // Initial language
}

const themeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_LANGUAGE:
      return {
        lng: payload,
      }
    default:
      return state
  }
}

export default themeReducer
