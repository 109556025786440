import { createSelector } from 'reselect'

const auth = (state) => state.auth

export const userInformationSelector = createSelector(
  auth,
  (item) => item.userInfo
)

export const authTokenSelector = createSelector(auth, (item) => item.authToken)
