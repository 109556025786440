import * as types from '../types'
const initialState = {
  contestsDetails: [],
  profileData: {},
  filtersList: [],
  explorePosts: [],
  leftMargin: 0,
  prizeReward: false,
}

const contestsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FETCH_ALL_CONTESTS:
      let result = []
      let items = payload
      if (state.filtersList.length) {
        const sorting = state.filtersList.map((item) => item.genre)
        sorting.forEach((key) => {
          let found = false
          items = items.filter((item) => {
            if (!found && item.genre === key) {
              result.push(item)
              found = true
              return false
            } else return true
          })
        })
      }

      return {
        ...state,
        contestsDetails: result.length ? result : payload,
      }
    case types.FETCH_POST_PROFILE:
      return {
        ...state,
        profileData: payload,
      }
    case types.FETCH_ALL_FILTERS:
      return {
        ...state,
        filtersList: payload,
      }
    case types.FETCH_EXPLORE_POSTS:
      return {
        ...state,
        explorePosts: payload,
      }
    case types.FETCH_USER_CONTESTS:
      return {
        ...state,
        userContest: payload,
      }
    case types.SET_LEFT_MARGIN:
      return {
        ...state,
        leftMargin: payload,
      }
    case types.PRIZE_AND_REWARD:
      return {
        ...state,
        prizeReward: payload,
      }
    default:
      return state
  }
}

export default contestsReducer
