import React from 'react'

import { Checkbox, Modal } from 'antd'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  getLanguageByContestId,
  updateUser,
} from '../../redux/actions/contests.action'
import { userInformationSelector } from '../../redux/selectors/auth.selector'
import CheckBoxGroup from './CheckBoxGroup'

const Filters = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userInfo = useSelector(userInformationSelector)
  const { filtersList } = useSelector((state) => {
    return state.contests
  })
  const [filtersData, setFiltersData] = React.useState([])
  const [selectedFilters, setSelectedFilters] = React.useState([])
  const [languageFilters, setLanguageFilters] = React.useState()
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    userInfo.languages
  )

  React.useEffect(() => {
    if (filtersData.length) {
      const temp = filtersData.map((filterItem) => {
        const data = {}
        data.genre = filterItem.genre
        const idsArray = userInfo.filters.find((filterOptions) => {
          return filterOptions.genre === data.genre
        })
        if (userInfo.filters.length) {
          data._ids =
            idsArray && Object.keys(idsArray).length ? idsArray._ids : []
        } else {
          const ids = []
          filterItem.contests.map((contest) => {
            ids.push(contest._id)
            return null
          })
          data._ids = ids
        }
        return data
      })
      setSelectedFilters(temp)
    }
  }, [filtersData, userInfo])

  React.useEffect(() => {
    setFiltersData(filtersList)
  }, [filtersList])

  const saveFilters = () => {
    const data = {
      filters: selectedFilters.filter((item) => item._ids.length),
      languages: selectedLanguage,
    }
    dispatch(updateUser(data))
    props.onCancel()
  }

  React.useEffect(() => {
    const langParams = {
      fileldName: 'languages',
    }
    dispatch(getLanguageByContestId(langParams)).then((res) => {
      if (res && res.result) {
        setLanguageFilters(res.data)
      }
    })
  }, [dispatch])

  const onCheckBoxChange = (checkedList) => {
    if (checkedList.target.value !== 'all') {
      setSelectedLanguage((selectedLanguage) => [
        ...selectedLanguage,
        checkedList.target.value,
      ])
    } else {
      setSelectedLanguage([])
    }
  }

  return (
    <Modal
      visible={props.filterPopVisible}
      onCancel={props.onCancel}
      footer={null}
      centered
      wrapClassName={
        'poppostdetailwrapper filtermainpopwrapper contests-filter-popup'
      }
    >
      <div className="filterpopwrapper">
        <div className="filtermobititle">
          <h4>Filter</h4>
          <p>Popularity Contests</p>
        </div>
        <button className="button white-btn" onClick={() => saveFilters()}>
          Done
        </button>
        <ScrollBar component="div">
          <div className="popupbody">
            <div className="popupbody-title">Content</div>
            <div className="filterblockwrapper filled-checkbox-wrapper">
              <Checkbox
                className="filled-checkbox"
                key={'all'}
                value="all"
                onChange={onCheckBoxChange}
                checked={selectedLanguage.length > 0 ? false : true}
              >
                All
              </Checkbox>
              {languageFilters
                ? languageFilters.map((language) => (
                    <Checkbox
                      className="filled-checkbox"
                      key={language}
                      value={language}
                      checked={
                        selectedLanguage.indexOf(language) > -1 ? true : false
                      }
                      onChange={onCheckBoxChange}
                    >
                      {language}
                    </Checkbox>
                  ))
                : null}
            </div>

            <div className="popupbody-title">Contests</div>
            {selectedFilters && filtersData && filtersData.length
              ? filtersData.map((filterItem) => {
                  let defaultData =
                    selectedFilters &&
                    selectedFilters.length &&
                    selectedFilters.find((filterObj) => {
                      return filterObj.genre === filterItem.genre
                    })

                  return (
                    <CheckBoxGroup
                      key={`checkbox${filterItem.genre}`}
                      defaultData={defaultData}
                      filterItem={filterItem}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={(data) => setSelectedFilters(data)}
                    />
                  )
                })
              : null}
          </div>
        </ScrollBar>
        {/* <div className="mobileview">
          <div className="popupbody">
          <div className="popupbody-title">Content</div>
            <div className="filterblockwrapper filled-checkbox-wrapper">
              <Checkbox className="filled-checkbox" checked="checked">All</Checkbox>
              <Checkbox className="filled-checkbox" >Abkhaz</Checkbox>
              <Checkbox className="filled-checkbox">Afar</Checkbox>
              <Checkbox className="filled-checkbox">Afrikaans</Checkbox>
              <Checkbox className="filled-checkbox">Akan</Checkbox>
              <Checkbox className="filled-checkbox">Albanian</Checkbox>
              <Checkbox className="filled-checkbox">Amharic</Checkbox>
              <Checkbox className="filled-checkbox">Arabic</Checkbox>
              <Checkbox className="filled-checkbox">Aragonese</Checkbox>
              <Checkbox className="filled-checkbox">Armenian</Checkbox>
              <Checkbox className="filled-checkbox">Bengali</Checkbox>
              <Checkbox className="filled-checkbox">English</Checkbox>
              <Checkbox className="filled-checkbox">Farsi - Persian</Checkbox>
              <Checkbox className="filled-checkbox">Hindi</Checkbox>
              <Checkbox className="filled-checkbox">Kannada</Checkbox>
            </div>

            <div className="popupbody-title">Contests</div>
            {selectedFilters && filtersData && filtersData.length
              ? filtersData.map((filterItem) => {
                  let defaultData =
                    selectedFilters &&
                    selectedFilters.length &&
                    selectedFilters.find((filterObj) => {
                      return filterObj.genre === filterItem.genre
                    })

                  return (
                    <CheckBoxGroup
                      key={`checkbox${filterItem.genre}`}
                      defaultData={defaultData}
                      filterItem={filterItem}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={(data) => setSelectedFilters(data)}
                    />
                  )
                })
              : null}
          </div>
        </div> */}
      </div>
    </Modal>
  )
}

export default Filters
