import Axios, { axiosInstance } from '../../config/axios.config'
import { handleAPIError } from '../../config/globalFunction'
import { LOGGED_IN, LOG_OUT, UPDATE_USER } from '../types'

export const login = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('users/send-verification-otp', data)
    return response.data ? response.data : response
  } catch (error) {
    handleAPIError(error)
    return error.response
  }
}

export const verifyOtp = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('users/verify-otp', data)
    if (response.data && response.data.result) {
      Axios.defaults.headers.common.Authorization = response.data.data.token
      dispatch({
        type: LOGGED_IN,
        payload: response.data.data,
      })
    }
    return response.data ? response.data : response
  } catch (error) {
    handleAPIError(error)
    return error.response
  }
}

export const register = (data, type = null) => async (dispatch) => {
  const response = await axiosInstance({
    method: 'PATCH',
    url: '/users',
    data,
  })
  if (response.data) {
    const updateType = type === 'UPDATE' ? UPDATE_USER : LOGGED_IN
    const userInfo = response.data.data
    dispatch({
      type: updateType,
      payload: userInfo,
    })
    return response.data
  }
  return response
}

export const logout = () => async (dispatch) => {
  Axios.defaults.headers.common.Authorization = null
  dispatch({
    type: LOG_OUT,
  })
  return 'true'
}
