import React from 'react'

import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { routePaths } from '../../config/globalConfig'
import { useWindowSize } from '../../config/globalFunction'
import {
  getUserTickets,
  notificationCount,
} from '../../redux/actions/contests.action'
import { userInformationSelector } from '../../redux/selectors/auth.selector'
import Filters from '../Filters'
import Notification from '../Notification'

const Header = React.forwardRef((props, ref) => {
  const history = useHistory()
  const [createProfilePopup, setCreateProfilePopup] = React.useState(false)
  const dispatch = useDispatch()
  const userInfo = useSelector(userInformationSelector)
  const [windowWidth] = useWindowSize()
  const location = useLocation()
  // const [moreMenuPopVisible, setMoreMenusPopVisible] = React.useState(false)
  const [filterPopVisible, setFilterPopVisible] = React.useState(false)
  const [notificationPopVisible, setNotificationPopVisible] = React.useState(
    false
  )
  const [notificationCountData, setNotificationCountData] = React.useState(0)
  const [, setTicketsTotal] = React.useState(userInfo.tickets.total || 0)
  const [dashboardAccess, setDashboardAccess] = React.useState(false)

  console.log(userInfo)

  React.useEffect(() => {
    dispatch(notificationCount()).then((res) => {
      if (res && res.result) {
        setNotificationCountData(res.data)
      }
    })
    dispatch(getUserTickets()).then((res) => {
      if (res && res.result && res.data.length > 0) {
        setTicketsTotal(res.data.tickets.total)
      }
    })
  }, [dispatch])

  return (
    <>
      <nav id="mobilemenu">
        <ul>
          <li className={location.pathname === '/home' ? 'active' : ''}>
            <a href={routePaths.home} title="Home">
              <img
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/home-outline.svg"
                alt=""
              />
              <img
                className="active-icon"
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/home-filled.svg"
                alt=""
              />
            </a>
          </li>
          <li
            className={location.pathname === '/talent-contest' ? 'active' : ''}
          >
            <a href={routePaths.talentContest} title="Search">
              <img
                src="https://contestee-website.s3.us-west-1.amazonaws.com/images/talent_contest.png"
                alt=""
              />
              <img
                className="active-icon"
                src="https://contestee-website.s3.us-west-1.amazonaws.com/images/talent_contest_filed.png"
                alt=""
              />
            </a>
          </li>
          <li className={location.pathname === '/search' ? 'active' : ''}>
            <a href={routePaths.search} title="Search">
              <img
                src="https://contestee-website.s3.us-west-1.amazonaws.com/images/search_new.png"
                alt=""
              />
              <img
                className="active-icon"
                src="https://contestee-website.s3.us-west-1.amazonaws.com/images/search_new_selected.png"
                alt=""
              />
            </a>
          </li>
          {/* <li className="morecat">
            <button
              className="blbutton"
              title="More"
              onClick={() => {
                setCreateProfilePopup(!createProfilePopup)
              }}
            >
              <img
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/moreicon.png"
                alt=""
              />
            </button>
          </li> */}
          <li className={location.pathname === '/explore' ? 'active' : ''}>
            <a href={routePaths.explore} title="Search">
              <img
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/grid.svg"
                alt=""
              />
              <img
                className="active-icon"
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/grid-s.svg"
                alt=""
              />
            </a>
          </li>

          {/* <li
            className={location.pathname === '/active-voters' ? 'active' : ''}
          >
            <a href={routePaths.activeVoters}>
              <img
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/voter_white.png"
                alt=""
              />
              <img
                className="active-icon"
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/voter_black.png"
                alt=""
              />
            </a>
          </li> */}

          <li
            className={
              location.pathname === '/profile'
                ? 'active profileMenu'
                : 'profileMenu'
            }
          >
            <a href={routePaths.profile} title="Profile">
              <img
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/profile-outline.svg"
                alt=""
              />
              <img
                className="active-icon"
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/profile-filled.svg"
                alt=""
              />
            </a>
          </li>
        </ul>
      </nav>
      {[
        '/celebrities',
        '/post-evaluation',
        '/contestant-location-ranking',
        '/how-it-works',
        '/tickets',
        '/contests-voted',
        '/contestants-voted',
        '/contestant-details',
        '/contest-info',
        '/become-contestant',
        '/become-host',
        '/host-post-detail',
        '/host-details',
        '/create-contest',
        '/judge-details',
        '/active-voters',
        '/search',
        '/talent-contest',
      ].includes(location.pathname) && windowWidth <= 1024 ? (
        <div ref={ref}></div>
      ) : (
        <header id="header">
          <div className="flexwrapper">
            <a href="/#" id="logo" title="Contestee">
              <img
                className="mobileview"
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/logo-black-new.png"
                alt=""
              />
              <img
                className="desktopview"
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/logo-black-new.png"
                alt=""
              />
            </a>
            <nav id="mainmenu" ref={ref}>
              <ul>
                <li className={location.pathname === '/home' ? 'active' : ''}>
                  <a href={routePaths.home} title="Home">
                    Home
                  </a>
                </li>

                <li
                  className={
                    location.pathname === '/talent-contest' ? 'active' : ''
                  }
                >
                  <a href={routePaths.talentContest} title="Talent Contest">
                    Talent
                  </a>
                </li>

                {/* <li className="morecat">
                  <button
                    className="blbutton"
                    title="More"
                    onClick={() => history.push(routePaths.becomeContestant)}
                  >
                    <img
                      src="https://contestee-website.s3-us-west-1.amazonaws.com/images/menuplusicon.svg"
                      alt=""
                    />
                  </button>
                  </li> */}
                <li>
                  <a href={routePaths.search} title="Explore">
                    Search
                  </a>
                </li>
                <li
                  className={location.pathname === '/explore' ? 'active' : ''}
                >
                  <a href={routePaths.explore} title="Explore">
                    Explore
                  </a>
                </li>
                <li
                  className={location.pathname === '/profile' ? 'active' : ''}
                >
                  <a href={routePaths.profile} title="Profile">
                    Profile
                  </a>
                </li>
              </ul>
            </nav>
            <ul id="headerright">
              <li>
                <button
                  className="blbutton"
                  onClick={() => history.push(routePaths.tickets)}
                >
                  <img
                    src="https://contestee-website.s3.us-west-1.amazonaws.com/images/tickets.svg"
                    alt=""
                  />
                  <span className="unreadnum">{userInfo.tickets.total}</span>
                </button>
              </li>

              <li className="notification">
                <button
                  className="blbutton"
                  onClick={() =>
                    setNotificationPopVisible(!notificationPopVisible)
                  }
                >
                  <img
                    src="https://contestee-website.s3-us-west-1.amazonaws.com/images/notification.svg"
                    alt=""
                  />
                  {notificationCountData > 0 ? (
                    <span className="unreadnum">
                      {notificationCountData > 99
                        ? '99+'
                        : notificationCountData}
                    </span>
                  ) : null}
                </button>
              </li>
              <li>
                <button
                  className="blbutton"
                  onClick={() => setFilterPopVisible(true)}
                >
                  <img
                    src="https://contestee-website.s3-us-west-1.amazonaws.com/images/filter-icon.svg"
                    alt=""
                  />
                </button>
              </li>
            </ul>
          </div>
          <Filters
            filterPopVisible={filterPopVisible}
            onCancel={() => setFilterPopVisible(!filterPopVisible)}
          />
          {/*<BecomeContestant
            moreMenuPopVisible={moreMenuPopVisible}
            onCancel={() => setMoreMenusPopVisible(!moreMenuPopVisible)}
            userInfo={userInfo}
          />*/}
          {notificationPopVisible ? (
            <Notification
              notificationPopVisible={notificationPopVisible}
              onCancel={() =>
                setNotificationPopVisible(!notificationPopVisible)
              }
              notificationCountData={(data) => setNotificationCountData(data)}
            />
          ) : null}
        </header>
      )}
      <Modal
        visible={createProfilePopup}
        centered
        footer={null}
        onCancel={() => {
          setCreateProfilePopup(!createProfilePopup)
        }}
        wrapClassName={'create-profile-nav-modal'}
      >
        <div className="create-profile-header">User Requests</div>
        <div className="create-profile-body">
          <button
            className="blbutton"
            onClick={() => {
              setCreateProfilePopup(!createProfilePopup)
              history.push('/become-contestant')
            }}
          >
            <em>
              <img
                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/create-profile-ic01.svg"
                alt=""
              />
            </em>
            <div className="info">
              <strong>Become Contestant</strong> Show your talent to the world
            </div>
          </button>
          {userInfo.host.state !== undefined &&
            userInfo.host.state === 'REJECTED' && (
              <button
                className="blbutton"
                onClick={() => {
                  setCreateProfilePopup(!createProfilePopup)
                  history.push('/become-host')
                }}
              >
                <em>
                  <img
                    src="https://contestee-website.s3-us-west-1.amazonaws.com/images/create-profile-ic02.svg"
                    alt=""
                  />
                </em>
                <div className="info">
                  <strong>Become Host</strong> Create your own stage to run any
                  contest
                </div>
              </button>
            )}
        </div>
      </Modal>

      <Modal
        visible={dashboardAccess}
        centered
        footer={null}
        onCancel={() => {
          setDashboardAccess(!dashboardAccess)
        }}
        wrapClassName={
          'poppostdetailwrapper filtermainpopwrapper contests-filter-popup'
        }
      >
        <div className="postblockwrapper howcontesteeworks mb45">
          <div className="postblock">
            <h3 className="postblocktitle">
              <span>Votes & Posts</span>Quick Access
            </h3>

            <div className="postblockcontent">
              <div className="postblocklinkslisting">
                <div className="postblocklinkslist c-pointer">
                  <img src="images/star_dash.png" alt="" />
                  <button
                    className="blbutton"
                    onClick={() =>
                      history.push({
                        pathname: '/contestants',
                        state: {
                          module: 'NEW_CONTESTANTS',
                        },
                      })
                    }
                  >
                    <span>New Talents</span>
                    <p>Enrolled recently</p>
                    <span className="minibtn">VIEW</span>
                  </button>
                </div>
                <div className="postblocklinkslist c-pointer">
                  <img
                    src="https://contestee-website.s3-us-west-1.amazonaws.com/images/tickets_dash.png"
                    alt=""
                  />
                  <button
                    className="blbutton"
                    onClick={() => {
                      setDashboardAccess(!dashboardAccess)
                      history.push('/tickets')
                    }}
                  >
                    <span>My Voting Tickets</span>
                    <p>Available to use for voting </p>
                    <span className="minibtn">VIEW</span>
                  </button>
                </div>
                <div className="postblocklinkslist c-pointer">
                  <img
                    src="https://contestee-website.s3-us-west-1.amazonaws.com/images/voteer.png"
                    alt=""
                  />
                  <button
                    className="blbutton"
                    onClick={() => {
                      setDashboardAccess(!dashboardAccess)
                      history.push('/contests-voted', {
                        constestantId: userInfo._id,
                      })
                    }}
                  >
                    <span>My Votes</span>
                    <p>Talent I've voted for</p>
                    <span className="minibtn">VIEW</span>
                  </button>
                </div>
                <div className="postblocklinkslist c-pointer">
                  <img
                    src="https://contestee-website.s3-us-west-1.amazonaws.com/images/post.png"
                    alt=""
                  />
                  <button
                    className="blbutton"
                    onClick={() => {
                      setDashboardAccess(!dashboardAccess)
                      history.push('/saved-post')
                    }}
                  >
                    <span>Saved Posts</span>
                    <p>My bookmark</p>
                    <span className="minibtn">VIEW</span>
                  </button>
                </div>
                <div className="postblocklinkslist c-pointer">
                  <img
                    src="https://contestee-website.s3-us-west-1.amazonaws.com/images/filtersss.png"
                    alt=""
                  />
                  <button
                    className="blbutton"
                    onClick={() => {
                      setDashboardAccess(!dashboardAccess)
                    }}
                  >
                    <span>Contest Filters</span>
                    <p>Filter </p>
                    <span className="minibtn">VIEW</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})

export default Header
