import * as types from '../types'
const initialState = {
  userInfo: {},
  authToken: '',
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGGED_IN:
      return {
        userInfo: payload,
        authToken: payload.token,
      }
    case types.UPDATE_USER:
      return {
        ...state,
        userInfo: payload,
      }
    default:
      return state
  }
}

export default authReducer
