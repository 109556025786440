import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import de from './locales/de'
import en from './locales/en'
import fa from './locales/fa'

// console.log('LanguageDetector', LanguageDetector)
i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'querystring', 'navigator', 'htmlTag', 'subdomain'],
      lookupQuerystring: 'lng',
    },
    // we init with resources
    resources: {
      en: en,
      de: de, // German Language
      fa: fa, // Farsi - Persian Language
    },
    // lng: 'fa',
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
      useSuspense: false,
    },
    whitelist: ['en', 'fa', 'de'], // important while overriding path detection to be limited with passed options only
  })
// i18n.languages = ['en', 'fa', 'de']

export default i18n
