import React, { useCallback } from 'react'

import { Empty, Modal, Spin, Tabs, message } from 'antd'
import moment from 'moment'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { routePaths } from '../../config/globalConfig'
import {
  deleteAllNotification,
  notificationCount,
  notifications,
  readNotification,
} from '../../redux/actions/contests.action'
import { userInformationSelector } from '../../redux/selectors/auth.selector'

const Notification = (props) => {
  const { TabPane } = Tabs
  const dispatch = useDispatch()
  const history = useHistory()
  const [notificationData, setNotificationData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasMoreContestant] = React.useState(true)
  const [limit] = React.useState(5)
  const [, setSkip] = React.useState(0)
  const userInfo = useSelector(userInformationSelector)

  const getNotification = useCallback(() => {
    // const params = {
    //   skip: skip,
    //   limit: limit,
    // }
    const params = {
      skip: 0,
      limit: 100,
    }
    setIsLoading(true)
    dispatch(notifications(params)).then((res) => {
      if (res && res.result) {
        setIsLoading(false)
        setNotificationData(res.data)
      }
    })
  }, [dispatch])

  const notiCountData = () => {
    dispatch(notificationCount()).then((res) => {
      if (res && res.result) {
        props.notificationCountData(res.data)
      }
    })
  }
  React.useEffect(() => {
    getNotification()
  }, [getNotification])

  const handleReadNotification = (notificationId, type) => {
    dispatch(readNotification(notificationId)).then((res) => {
      if (res && res.result) {
        getNotification()
        notiCountData()
        props.onCancel(false)
        if (type === 'DAILY_TICKETS') {
          history.push(routePaths.tickets)
        } else if (type === 'APPROVED_HOST') {
          if (userInfo.host.state !== 'CONTRACT_SIGNED') {
            history.push(routePaths.signFile)
          } else {
            message.error('Agreement already signed')
          }
        }
      }
    })
  }

  const handleNotificationDeleteAll = () => {
    const idsArray = notificationData.map((item) => item._id)
    console.log(idsArray)
    const params = { ids: idsArray }
    dispatch(deleteAllNotification(params)).then((res) => {
      if (res && res.result) {
        getNotification()
        notiCountData()
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  const handleScroll = useCallback(() => {
    if (isLoading || !!!hasMoreContestant) {
      return
    }
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 50
    ) {
      setSkip((skip) => skip + limit)
    }
  }, [hasMoreContestant, isLoading, limit])

  return (
    <Modal
      visible={props.notificationPopVisible}
      onCancel={props.onCancel}
      footer={null}
      centered
      wrapClassName={
        'poppostdetailwrapper fullpoppostdetailwrapper notificationwrapper notificationPopup'
      }
    >
      <div className="popupbody">
        <h2 className="modal-title">Notifications</h2>
        <Tabs defaultActiveKey="1" className="right-text">
          <TabPane tab="Alerts" key="1">
            <Spin tip="Loading...." spinning={isLoading}>
              <div className="alertswrapper">
                <ScrollBar component="div">
                  <ul>
                    {notificationData && notificationData.length ? (
                      notificationData.map((notification, index) => {
                        return (
                          <li
                            key={`notification-${index}`}
                            className={
                              notification.isRead === false ? 'unread' : null
                            }
                            onClick={() =>
                              handleReadNotification(
                                notification._id,
                                notification.notificationType
                              )
                            }
                          >
                            <button className="blbutton">
                              <figure>
                                <img
                                  src="https://contestee-website.s3-us-west-1.amazonaws.com/images/circlelogo.svg"
                                  alt=""
                                />
                              </figure>
                              <div className="alertscontent">
                                <h5>{notification.metadata.title}</h5>
                                <p>{notification.metadata.text}</p>
                                <span>
                                  {moment(notification.created).format(
                                    'MMM.DD.YYYY hh:mm a'
                                  )}
                                </span>
                              </div>
                              <img
                                src="https://contestee-website.s3-us-west-1.amazonaws.com/images/alertsarrowright.svg"
                                alt=""
                              />
                            </button>
                          </li>
                        )
                      })
                    ) : (
                      <Empty description={'No notifications available yet!'} />
                    )}
                  </ul>
                </ScrollBar>
                {notificationData && notificationData.length ? (
                  <div className="btn-wrapper">
                    <button
                      className="button link-btn"
                      onClick={() => handleNotificationDeleteAll()}
                    >
                      Delete All
                    </button>
                  </div>
                ) : null}
              </div>
            </Spin>
          </TabPane>
          {/*<TabPane tab="Message Box" key="2">
            <div className="no-data-found">
              <h6>No Data Found!</h6>
            </div>
                </TabPane>*/}
        </Tabs>
      </div>
    </Modal>
  )
}

export default Notification
