import React, { Suspense, useEffect } from 'react'

import { Spin } from 'antd'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import ErrorBoundary from './components/ErrorBoundary'
import './config/i18n/i18n'
import App from './containers/App/App'
import configureStore from './redux/store'
import reportWebVitals from './reportWebVitals'

const store = configureStore()

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname !== '/' || pathname !== '/contact-us') {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}

const MediaNet = ({ divId, size }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        window._mNHandle.queue.push(function () {
          window._mNDetails.loadTag(divId, size, divId)
        })
      } catch (error) {}
    }
  }, [divId, size])

  return <div id={divId} />
}

if (process.env.NODE_ENV !== 'development') console.log = () => {}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={store.__PERSISTOR} loading={null}>
      <ErrorBoundary>
        <BrowserRouter>
          <ScrollToTop />
          <MediaNet size="300x250" divId="235864985" />
          <Suspense fallback={<Spin className="fixed-spin" />}>
            <App />
          </Suspense>
        </BrowserRouter>
      </ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
