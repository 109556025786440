import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div id="wrapper" className="landing terms-page">
          <div className="page-not-found">
            <em className="thumb">
              <img src="/images/coming-soon.svg" alt="" />
            </em>
            <h3>Something went wrong.</h3>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
