import React from 'react'

import { message } from 'antd'

import { logout } from '../redux/actions/auth.action'
import { cancelSource } from './axios.config'
import { toastDuration } from './globalConfig'

export const useWindowSize = () => {
  const [size, setSize] = React.useState([0, 0])
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export const useOnScreen = (ref, offset = 0) => {
  const [isIntersecting, setIntersecting] = React.useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { rootMargin: `${offset}px` }
    )
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [offset, ref])

  return isIntersecting
}

export const scrollToRefLanding = (ref) =>
  // ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  window.scrollTo({ top: ref.current.offsetTop - 74, behavior: 'smooth' })

export const scrollToRef = (ref) =>
  // ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' })

export const numberFormat = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumSignificantDigits: 1,
  }).format(number)
}

export const handleAPIError = (error, dispatch) => {
  let errorMessage = 'Something went wrong!!'
  if (error.response && error.response.data.msg) {
    errorMessage = error.response.data.msg
  }

  if (error && error.response && error.response.status === 401) {
    message.error(errorMessage, toastDuration, () => {
      dispatch(logout())
      cancelSource.cancel('You have been logged out')
      window.location.href = '/'
    })
  }
  message.error(errorMessage, toastDuration)
}

export const isValidURL = (str) => {
  try {
    new URL(str)
  } catch (_) {
    return false
  }

  return true
}

export const sortByKey = (array, key) => {
  return array.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    return x < y ? -1 : x > y ? 1 : 0
  })
}

export const intToString = (num) => {
  if (num < 1000) {
    return num
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ]
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].v) {
      break
    }
  }
  return (
    (num / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
    si[i].s
  )
}

export const getInitials = (name) => {
  let nameArray = name.split(' ')

  let initials = ''
  if (nameArray.length === 1) {
    return nameArray[0].charAt(0) + '' + nameArray[0].charAt(1)
  } else {
    initials = nameArray[0].charAt(0)
  }
  for (let i = nameArray.length - 1; i < nameArray.length; i++) {
    initials += nameArray[i].charAt(0)
  }

  return initials.toUpperCase()
}
