import 'antd/dist/antd.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import React, { lazy, useEffect } from 'react'

//import { Modal, message } from 'antd'
import { message } from 'antd'
import jwtDecode from 'jwt-decode'
//import moment from 'moment'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'video-react/dist/video-react.css'
import Layout from '../../components/Layout'
import { routePaths, toastDuration } from '../../config/globalConfig'
import i18nConfig from '../../config/i18n/i18n'
import './app.css'
import { logout } from '../../redux/actions/auth.action'
import { getAllFilters } from '../../redux/actions/contests.action'
import {
  authTokenSelector,
  userInformationSelector,
} from '../../redux/selectors/auth.selector'

const Landing = lazy(() => import('../Landing'))
const PostEvaluation = lazy(() => import('../Profile/PostEvaluation'))
const PrivacyPolicy = lazy(() => import('../PrivacyPolicy'))
const TermsCondition = lazy(() => import('../TermsCondition'))
const EULA = lazy(() => import('../EULA'))
const PageNotFound = lazy(() => import('../../components/PageNotFound'))
const ContactUs = lazy(() => import('../Landing/ContactUs'))
const HostRequirements = lazy(() => import('../HostRequirements'))
const Sponsor = lazy(() => import('../Sponsor'))
const ContestantGuide = lazy(() => import('../ContestantGuide'))
const VoterGuide = lazy(() => import('../VoterGuide'))
const HostGuide = lazy(() => import('../HostGuide'))
const Prizes = lazy(() => import('../Landing/Prizes'))
const ContestDetails = lazy(() => import('../ContestDetails'))
const Voters = lazy(() => import('../Voters'))
const ProfilePage = lazy(() => import('../Profile'))
const SavedPost = lazy(() => import('../Profile/SavedPost'))
const Home = lazy(() => import('../Home'))
const TicketsPage = lazy(() => import('../Tickets'))
const SearchPage = lazy(() => import('../Home/Search'))
const PopularContest = lazy(() => import('../Home/PopularContest'))
const ContestVoted = lazy(() => import('../ContestVoted'))
const ContestantsVoted = lazy(() => import('../ContestantsVoted'))
const CelebritiesLegends = lazy(() => import('../Home/CelebritiesLegends'))
const BecomeHost = lazy(() => import('../Become/Host'))
const SignFile = lazy(() => import('../Become/Host/SignFile'))
const HostInfo = lazy(() => import('../Home/Search/HostInfo'))
const StarBuzzer = lazy(() => import('../Home/Search/StarBuzzer'))
const TurnOuts = lazy(() => import('../Home/Search/TurnOuts'))
const EditProfile = lazy(() => import('../Profile/EditProfile'))
const JudgeInfo = lazy(() => import('../Profile/JudgeInfo'))
const Contestant = lazy(() => import('../Home/Search/Contestant'))
const BecomeContestant = lazy(() => import('../Become/Contestant'))
const PostsPage = lazy(() => import('../Explore'))
const BillboardApprove = lazy(() => import('../Home/Search/BillboardApprove'))
const PostDetail = lazy(() => import('../PostDetail'))
const CreateContest = lazy(() => import('../CreateContest'))
const ContestStatus = lazy(() => import('../ContestStatus'))
const WinnerList = lazy(() => import('../Home/Search/WinnerList'))
const Talent = lazy(() => import('../Home/Talents'))
const ActiveHost = lazy(() => import('../Voters/ActiveHost'))
const ContestPost = lazy(() => import('../PostDetail/ContestPost'))
const PostDetailMore = lazy(() => import('../PostDetail/PostDetailMore'))
const ContestantsLocationRanking = lazy(() =>
  import('../PostDetail/PostDetailMore/ContestantsLocationRanking')
)

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userInfo = useSelector(userInformationSelector)
  const authToken = useSelector(authTokenSelector)
  const decodedToken = jwtDecode(authToken)

  if (decodedToken.exp < new Date().getTime() / 1000) {
    message.error(
      'Your session is expired, please login again',
      toastDuration,
      () => {
        dispatch(logout())
        history.push(routePaths.root)
      }
    )
    return false
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        Object.keys(userInfo).length ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{ pathname: routePaths.root, state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const data = {
  visibility: true,
  sortBy: 'displayOrder',
  sortDir: 1,
  others: false,
}

const App = (props) => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const userInfo = useSelector(userInformationSelector)

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      document.onkeydown = (event) => {
        if (event.keyCode === 123) {
          return false
        }
        if (
          event.ctrlKey &&
          event.shiftKey &&
          event.keyCode === 'I'.charCodeAt(0)
        ) {
          return false
        }
        if (
          event.ctrlKey &&
          event.shiftKey &&
          event.keyCode === 'C'.charCodeAt(0)
        ) {
          return false
        }
        if (
          event.ctrlKey &&
          event.shiftKey &&
          event.keyCode === 'J'.charCodeAt(0)
        ) {
          return false
        }
        if (event.ctrlKey && event.keyCode === 'U'.charCodeAt(0)) {
          return false
        }
      }
    }
  }, [])

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      dispatch(getAllFilters(data))
    }
  }, [dispatch, userInfo])

  // useEffect(() => {
  //   if (Object.keys(userInfo).length) {
  //     const date = new Date()
  //     const currentDate = date.getTime()
  //     const lastTicketAssign = moment(
  //       userInfo.tickets.lastFreeTicketsGiven,
  //       'YYYY-MM-DD HH:mm:ssZ'
  //     )
  //       .toDate()
  //       .getTime()

  //     const difference = currentDate - lastTicketAssign
  //     const hoursDifference = Math.floor(difference / 1000 / 60 / 60)

  //     if (hoursDifference > 1) {
  //       // Modal.info({
  //       //   title: 'Ticket',
  //       //   content: 'Your Recived new 10 tickets',
  //       // })
  //     }
  //   }
  // }, [dispatch, userInfo])

  return (
    <I18nextProvider i18n={i18nConfig}>
      <BrowserRouter basename={`/${i18n.language}/`} forceRefresh={true}>
        <Switch>
          <Route path={routePaths.privacyPolicy} component={PrivacyPolicy} />
          <Route
            path={routePaths.hostRequirements}
            component={HostRequirements}
          />
          <Route path={routePaths.sponsor} component={Sponsor} />
          <Route
            path={routePaths.contestantGuide}
            component={ContestantGuide}
          />
          <Route path={routePaths.voterGuide} component={VoterGuide} />
          <Route path={routePaths.hostGuide} component={HostGuide} />
          <Route
            path={routePaths.termsAndCondition}
            component={TermsCondition}
          />
          <Route path={routePaths.contactUs} component={ContactUs} />
          <Route path={routePaths.prizes} component={Prizes} />
          <Route path={routePaths.eula} component={EULA} />

          <PrivateRoute path={routePaths.search} component={SearchPage} />
          <PrivateRoute path={routePaths.tickets} component={TicketsPage} />
          <PrivateRoute path={routePaths.home} component={Home} />
          <PrivateRoute path={routePaths.activeVoters} component={Voters} />
          <PrivateRoute path={routePaths.profile} component={ProfilePage} />
          <PrivateRoute path={routePaths.savedPost} component={SavedPost} />
          <PrivateRoute
            path={routePaths.contestantLocationRanking}
            component={ContestantsLocationRanking}
          />
          <PrivateRoute
            path={routePaths.postDetailSettings}
            component={PostDetailMore}
          />
          <PrivateRoute
            path={routePaths.contestInfo}
            component={ContestDetails}
          />
          <PrivateRoute
            path={routePaths.contestsVoted}
            component={ContestVoted}
          />
          <PrivateRoute
            path={routePaths.contestantsVoted}
            component={ContestantsVoted}
          />
          <PrivateRoute
            path={routePaths.popularContests}
            component={PopularContest}
          />
          <PrivateRoute
            path={routePaths.contestantStatus}
            component={ContestStatus}
          />
          <PrivateRoute
            path={routePaths.celebrities}
            component={CelebritiesLegends}
          />
          <PrivateRoute
            path={routePaths.becomeContestant}
            component={BecomeContestant}
          />

          <PrivateRoute path={routePaths.savedPost} component={SavedPost} />
          <PrivateRoute
            path={routePaths.billBoardPost}
            component={BillboardApprove}
          />

          <PrivateRoute path={routePaths.becomeHost} component={BecomeHost} />
          <PrivateRoute path={routePaths.signFile} component={SignFile} />
          <PrivateRoute path={routePaths.hostDetails} component={HostInfo} />
          <PrivateRoute path={routePaths.contestants} component={StarBuzzer} />
          <PrivateRoute path={routePaths.turnouts} component={TurnOuts} />
          <PrivateRoute path={routePaths.editProfile} component={EditProfile} />
          <PrivateRoute path={routePaths.judgeDetails} component={JudgeInfo} />
          <PrivateRoute
            path={routePaths.contestantDetails}
            component={Contestant}
          />
          <PrivateRoute path={routePaths.explore} component={PostsPage} />
          <PrivateRoute path={routePaths.postDetail} component={PostDetail} />
          <PrivateRoute
            path={routePaths.createContest}
            component={CreateContest}
          />
          <PrivateRoute
            path={routePaths.contestInfo}
            component={ContestDetails}
          />
          <PrivateRoute
            path={routePaths.postEvaluation}
            component={PostEvaluation}
          />
          <PrivateRoute path={routePaths.talentContest} component={Talent} />
          <PrivateRoute path={routePaths.winnerList} component={WinnerList} />
          <PrivateRoute path={routePaths.ActiveHost} component={ActiveHost} />
          <PrivateRoute path={routePaths.ContestPost} component={ContestPost} />

          <Route
            path={[routePaths.root, `${routePaths.root}/${i18n.language}/`]}
            exact
            component={Landing}
          />

          <Route path={routePaths.notFound} component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </I18nextProvider>
  )
}

export default App
